import { WINDOW } from '@ng-toolkit/universal';
import { Component, Inject, OnInit, HostListener, ChangeDetectorRef, ViewRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './common.service';
import { CanonicalService } from './canonical.service';
import { DataSharingService } from './data-share.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { Globals } from './globals';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

import { DeviceDetectorService } from 'ngx-device-detector';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'menuhuts';
  public globals: Globals;
  public appType: any;
  public langId: any;
  public language: any;
  public langlist: any;
  route: string;
  public langFlag = false;
  interval: any;
  visible = true;
  public stickyheight;
  isUserLoggedIn: boolean;
  public userdetails: any;
  public userId;
  public headerType = true;
  public CartDish: any;
  public cartQuantity = 0;
  public logoImage = './assets/images/logo.png';
  public rotateImage = './assets/images/rotate.png';
  public acptImage = './assets/images/acpt.png';
  public footerLogoImage = './assets/images/footer-logo.png';

  public languageResponse;


  public paymentStripe: boolean = false;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    private canonicalService: CanonicalService,
    private comservice: CommonService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    location: Location,
    private router: Router,
    private dataSharingService: DataSharingService,
    private cdRef: ChangeDetectorRef,
    public apiservice: ApiService,
    globals: Globals,
    private titleService: Title,
    private metaService: Meta,
    private deviceService: DeviceDetectorService
  ) {


    this.spinner.show();
    this.globals = globals;
    this.appType = this.globals.APP_TYPE;
    const parameters = { f: 'fetchlang' };
    this.http.post(this.globals.API_ENDPOINT_LANGUAGE, JSON.stringify(parameters))
    .subscribe(res => {
      this.langFlag = true;
      this.languageResponse = res;
      this.globals.LANGUAGE = this.languageResponse.data;
      this.globals.LANG_ID = this.languageResponse.langId;
      this.globals.LANG_LIST = this.languageResponse.langlist;
      this.langlist = this.globals.LANG_LIST;
      if (this.comservice.getFromLocal('langId') === undefined || this.comservice.getFromLocal('langId') === null) {
        this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
        this.langId = this.globals.LANG_ID;
      } else {
        let lang;
        lang = this.comservice.getFromLocal('langId');
        this.globals.LANG_ID = lang;
        this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
      }
      this.canonicalService.setCanonicalURL();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
    });

    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
      } else {
        this.route = 'Home';
      }
      let strPath;
      strPath = location.path().split('/');
      
      if(strPath[1] === 'payment') {
        this.paymentStripe = true;
      }
      
    });
    this.dataSharingService.isRecoveryPageLoaded.subscribe(value => {
      if (value === true) {
        this.route = 'Home';
        this.dataSharingService.isRecoveryPageLoaded.next(false);
      }
    });
    this.dataSharingService.isRegisterPageLoaded.subscribe(value => {
      if (value === true) {
        this.route = 'Home';
        this.dataSharingService.isRegisterPageLoaded.next(false);
      }
    });
    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
      this.fetchData();
    });
    this.dataSharingService.isSingleBusiness.subscribe(value => {
      if (value) {
        this.headerType = value;
        setTimeout(() => {
          if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
            this.cdRef.detectChanges();
          }
        }, 10);
      }
    });
    this.dataSharingService.isCartAdded.subscribe(value => {
      if (value === true) {
        this.CartDish = this.comservice.get('cartDetails');
        this.cartQuantity = 0;
        for (let cart of this.CartDish) {
          for (let dish of cart.dish) {
            this.cartQuantity = this.cartQuantity + dish.quantity;
          }
        }
      }
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.titleService.setTitle('Wild Spice Indian Restaurant');
    this.metaService.updateTag({name: 'keywords', content: 'Wild Spice Indian Restaurant Esmonde St, Goreybridge, Gorey, Co. Wexford, Ireland'});
    this.metaService.updateTag({name: 'description', content: 'Wild Spice Indian Restaurant Esmonde St, Goreybridge, Gorey, Co. Wexford, Ireland'});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'});

    this.metaService.updateTag({property: 'og:url', content: this.globals.SITE_URL});

    this.userId = this.comservice.getFromLocal('userId');
    if (this.userId > 0) {
      this.isUserLoggedIn = true;
      this.userdetails = this.comservice.getFromLocal('user-details');
    }
    this.CartDish = this.comservice.get('cartDetails');
    if (this.CartDish !== '') {
      this.cartQuantity = 0;
      for (let cart of this.CartDish) {
        for (let dish of cart.dish) {
          this.cartQuantity = this.cartQuantity + dish.quantity;
        }
      }
    }

  }
  close() {
    this.visible = false;
  }
  fetchData() {
    this.userdetails = this.comservice.getFromLocal('user-details');
  }
  goToLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '500px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  goToLogout() {
    this.comservice.clear();
    this.comservice.deleteService('businessID');
    this.comservice.deleteService('businessDetails');
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    this.comservice.removeFromLocal('businessID');
    this.comservice.removeFromLocal('user-details');
    this.comservice.removeFromLocal('userId');
    this.isUserLoggedIn = false;
    this.userdetails = {};
    this.router.navigate(['/']);
  }
  goToBusiness() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/' + this.CartDish[0].details.slug]);
    });
  }
  onLangChange(event): void {
    this.globals.LANG_ID = event;
    this.langId = this.globals.LANG_ID;
    this.comservice.saveInLocal('langId', this.globals.LANG_ID);
    window.location.reload();
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.stickyheight = number;
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    if (event.target.screen.orientation.type === 'landscape-primary') {
      $('.web-orientation').addClass('landscape');
      $('.maincontent').hide();
    }
    if (event.target.screen.orientation.type === 'portrait-primary') {
      $('.web-orientation').removeClass('landscape');
      $('.maincontent').show();
    }
  }
}
