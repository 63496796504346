<div class="list-banner">
  <div class="list-banner-prlx">
    <div class="search-br">
      <ul>
        <li>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
              <span>
                <img [lazyLoad]="buildingsImage" />
              </span>
              </div>
              <input type="text" autocomplete="off" class="form-control" [(ngModel)]="searchText" placeholder="{{language.BUSINESS_NAME}}">
              <div class="input-group-addon">
                <a href="javascript:void(0)"><i class="fa fa-search" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>
<!-----------list-item------------->
<section class="list-item-div">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="search_result divA">
          <div class="src-hdn">
            <h4>{{language.DELIVERY_TYPE}}</h4>
            <div class="row">
              <div class="col-md-3 col-sm-4 col-xs-lg-12" *ngFor="let type of orderType">
                <div class="icheck-material-pink">
                  <input type="checkbox" id="orderType_{{type.id}}" (click)="businessFilter('order',type.id)" [(ngModel)]="type.selected">
                  <label for="orderType_{{type.id}}">{{type.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="src-hdn">
            <h4>{{language.BUSINESS_STATUS}}</h4>
            <div class="row">
              <div class="col-md-3 col-sm-4 col-xs-lg-12" *ngFor="let status of businessStatus">
                <div class="icheck-material-pink">
                  <input type="checkbox" id="businessStatus_{{status.id}}" (click)="businessFilter('status',status.id)" [(ngModel)]="status.selected">
                  <label for="businessStatus_{{status.id}}">{{status.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="src-hdn">
            <h4>{{language.CUISINE}}</h4>
            <div class="row">
              <div class="col-md-3 col-sm-4 col-xs-6 col-xs-lg-12" *ngFor="let cuisine of allCuisine">
                <div class="icheck-material-pink">
                  <input type="checkbox" id="cuisines_{{cuisine.id}}" (click)="businessFilter('cuisine',cuisine)" [(ngModel)]="cuisine.selected">
                  <label for="cuisines_{{cuisine.id}}">{{cuisine.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="src-hdn">
            <h4>{{language.CATEGORY}}</h4>
            <div class="row">
              <div class="col-md-3 col-sm-4 col-xs-6 col-xs-lg-12" *ngFor="let cat of allCategory">
                <div class="icheck-material-pink">
                  <input type="checkbox" id="category_{{cat.id}}" (click)="businessFilter('cat',cat)" [(ngModel)]="cat.selected">
                  <label for="category_{{cat.id}}">{{cat.name}}</label>
                </div>
               </div>
            </div>
          </div>
          <div class="flx-btn">
            <button type="button" class="cmn-button" (click)="applyFilter()">{{language.APPLY}}</button>
            &nbsp;&nbsp;
            <button type="button" class="clr-btn cmn-button" (click)="clearFilter()">{{language.CLEAR}}</button>
          </div>
        </div>
        <div class="result">
          <p>{{language.SHOWING}} {{(allBusiness | filter : searchText).length}} {{language.RESTAURANT}}</p>
          <span>
            <a href="javascript:void(0)" class="open-search" (click)="toggleShowDiv('divA')">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <g>
                  <g>
                    <g>
                      <circle cx="256" cy="256" r="64" />
                      <circle cx="256" cy="448" r="64" />
                      <circle cx="256" cy="64" r="64" />
                    </g>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </a>

          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6 col-xs-6 col-xs-lg-12" *ngFor="let business of allBusiness | filter : searchText">
        <div class="list-item">


          <div class="list-item-pic" (click)="openBusiness(business.slug)">
            <div class="ribbon-div">
              <span *ngIf="business.is_popular == 1" class="popular-ribbon">{{language.POPULAR}}</span>
              <span *ngIf="business.is_featured == 1" class="ftrd-ribbon">{{language.FEATURED}}</span>
              <span *ngIf="business.is_sponsored == 1" class="sponsored-ribbon">{{language.SPONSORED}}</span>
            </div>
            <div class="over-lay"></div>
            <span *ngIf="business.open" class="mat-open">{{language.OPEN}}</span>
            <span class="cls" *ngIf="business.open == false">{{language.CLOSE}}</span>
            <img [lazyLoad]="business.banner" />
          </div>
          <div class="list-item-dtl">
            <div class="cmp-logo" (click)="openBusiness(business.slug)">
              <img [lazyLoad]="business.logo" />
            </div>
            <h4 class="block-with-text" (click)="openBusiness(business.slug)">{{business.name}}</h4>
            <p class="cnt-itm">
         <span><i class="fa fa-phone" aria-hidden="true"></i></span>
              <a href="tel:+{{business.mobile}}">{{business.mobile}}</a>
            </p>
            <p class="cnt-itm">
              <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>{{business.address}}</p>
            <div class="addopn">
              <button class="ctgry">
                <i class="fa fa-cutlery" aria-hidden="true"></i>
              </button>
              <div class="right-adon">
                <button  (click)="contactUs(business);" class="contact">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </button>
                <button  (click)="addToFav(business);" class="addtofav" [ngClass]="{'active': business.addtofav == true }">
                  <i class="fa fa-heart" aria-hidden="true"></i>
                </button>
                <button  class="rtng">
                  <i class="fa fa-star" aria-hidden="true"></i><span class="rtng-txt">{{business.ratings}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
