import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementRef, PLATFORM_ID } from '@angular/core';
import { Inject, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  options = { autoHide: true, scrollbarMinSize: 100 };
  public globals: Globals;
  route: string;
  public hide = true;
  public signuphide = true;
  public prev: any;
  private loggedIn: boolean;
  public loginform: any = {
    email: '',
    password: ''
  };
  public signupuser: any = {name: '', last_name: '', email: '', pass: '', terms: false };
  public forgot: any = {email: ''};
  public language;
  public loginPopup = true;
  public signinPopup = false;
  public forgotPopup = false;

  @ViewChild('lemail', {static: false}) lemail: ElementRef;
  @ViewChild('lpassword', {static: false}) lpassword: ElementRef;

  @ViewChild('sname', {static: false}) public sname: ElementRef;
  @ViewChild('slname', {static: false}) public slname: ElementRef;
  @ViewChild('semail', {static: false}) public semail: ElementRef;
  @ViewChild('spass', {static: false}) public spass: ElementRef;
  @ViewChild('femail', {static: false}) public femail: ElementRef;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < LoginComponent > ,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    private dataSharingService: DataSharingService,
    private comservice: CommonService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
  }

  ngOnInit() {
    let uid;
    uid = this.comservice.getFromLocal('userId');
    let cartDish;
    cartDish = this.comservice.getService('cartDetails');
    let businessDetails;
    businessDetails = this.comservice.getService('businessDetails');
    if (cartDish !== undefined) {
      if (cartDish.length > 0 && uid > 0) {
        this.router.navigate(['/' + businessDetails.slug]);
      }
    } else {
      if (uid > 0) {
        this.router.navigate(['/']);
      }
    }

    this.prev = this.comservice.getService('prevroute');
    $('.button').click(function () {
      let buttonId;
      buttonId = $(this).attr('id');
      $('#modal-container').removeAttr('class').addClass(buttonId);
      $('body').addClass('modal-active');
    });

    $('.close-modal').click(function () {
      $('#modal-container').addClass('out');
      $('body').removeClass('modal-active');
    });
  }

  onLoginSubmit() {
    if (this.loginform.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.lemail.nativeElement.focus();
      }
    } else if (this.loginform.password === '') {
      this.snackBar.open(this.language.ENTER_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.lpassword.nativeElement.focus();
      }
    }
    this.apiservice.getLogin(this.loginform.email, this.loginform.password).subscribe(res => {
      if (res.status) {
        this.comservice.setService('user-details', res.result);
        this.comservice.saveInLocal('user-details', res.result);
        this.comservice.saveInLocal('userId', res.result.id);
        this.apiservice.getLoginCheck(res.result.id).subscribe(result => {
          this.dataSharingService.isUserLoggedIn.next(true);
          let prevroute;
          prevroute = this.comservice.getService('prevroute');
          if (prevroute === 'checkout') {
            this.router.navigate(['/' + prevroute]);
          }
          this.onNoClick();
        });
      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
  changeToLogin() {
    this.loginPopup = true;
    this.signinPopup = false;
    this.forgotPopup = false;
  }
  changeTosignUp() {
    this.loginPopup = false;
    this.signinPopup = true;
    this.forgotPopup = false;
  }
  onSignUpSubmit() {
    if (this.signupuser.name === '') {
      this.snackBar.open(this.language.ENTER_FIRST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.sname.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.last_name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_LAST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.slname.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.semail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.signupuser.email.indexOf('@');
    let dotpos;
    dotpos = this.signupuser.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.signupuser.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.semail.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.pass === '') {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.spass.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.pass.length < 6) {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD_LENGTH, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.spass.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.terms === false) {
      this.snackBar.open(this.language.CHECK_PRIVACY_TERMS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    this.apiservice.setSignUp(this.signupuser).subscribe(res => {
      if (res.status) {
        this.comservice.setService('user-details', res.result);
        this.comservice.saveInLocal('user-details', res.result);
        this.comservice.saveInLocal('userId', res.result.id);
        this.apiservice.getLoginCheck(res.result.id).subscribe(rest => {
          this.dataSharingService.isUserLoggedIn.next(true);
          let prevroute;
          prevroute = this.comservice.getService('prevroute');
          if (prevroute === 'checkout') {
            this.router.navigate(['/' + prevroute]);
          }
          this.onNoClick();
        });
      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  changeToForgotPass() {
    this.loginPopup = false;
    this.signinPopup = false;
    this.forgotPopup = true;
  }
  onForgotSubmit() {
    if (this.forgot.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.femail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.forgot.email.indexOf('@');
    let dotpos;
    dotpos = this.forgot.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.forgot.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.femail.nativeElement.focus();
      }
      return false;
    }
    this.apiservice.getForgot(this.forgot).subscribe(res => {
      if (res.status) {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.onNoClick();
        this.forgot = { email: '' };
      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  guestCheckOut() {
    this.onNoClick();
    this.router.navigate(['/checkout']);
    this.comservice.scrollTop();
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
