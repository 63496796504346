<div>
  <div class="animated-background" *ngIf="!businessLoad">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-sm-12">
          <div class="skl-bdy">
            <div class="skl-lft">
              <div class="skl-logo">
              </div>
              <div class="skl-dtls">
                <div class="skl-hdn"></div>
                <div class="skl-prgf"></div>
                <div class="skl-prgf" style="width: 50%"></div>
                <div class="skl-prgf" style="width: 45%"></div>
                <div class="skl-prgf"></div>
                <ul class="skl-pymnt">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="skl-ryt">

              <ul class="skl-adns">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <ng-template #subContainer1></ng-template>
  <ng-template #subContainer2></ng-template>
</div>
