<div class="row">
  <div class="col-md-6 col-xs-6 col-xs-12">
    <mat-card class="tb-dtls">
      <div class="business-adress">
        <h4>{{businessDetails.name}}</h4>
        <p><span>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 59 59" style="enable-background:new 0 0 59 59;" xml:space="preserve">
              <path style="fill:#ED8A19;"
                d="M45.07,59c-0.553,0-1-0.447-1-1s0.447-1,1-1H47c0.553,0,1.035,0.447,1.035,0.999
	c0,0.553-0.412,1-0.965,1v-1l-0.029,1L45.07,59z M41.07,59h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
	S41.623,59,41.07,59z M35.07,59h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S35.623,59,35.07,59z M29.07,59h-2
	c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S29.623,59,29.07,59z M23.07,59h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
	c0.553,0,1,0.447,1,1S23.623,59,23.07,59z M17.07,59h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S17.623,59,17.07,59
	z M50.537,57.282c-0.216,0-0.434-0.07-0.617-0.214c-0.435-0.342-0.509-0.971-0.168-1.404c0.325-0.413,0.548-0.882,0.663-1.392
	c0.121-0.539,0.656-0.877,1.195-0.756s0.877,0.656,0.756,1.195c-0.178,0.792-0.538,1.549-1.042,2.188
	C51.127,57.151,50.834,57.282,50.537,57.282z M50.538,51.719c-0.297,0-0.59-0.131-0.787-0.382c-0.32-0.407-0.734-0.744-1.197-0.974
	c-0.494-0.246-0.696-0.846-0.45-1.341c0.246-0.494,0.844-0.697,1.341-0.45c0.727,0.361,1.376,0.89,1.879,1.528
	c0.341,0.434,0.267,1.063-0.168,1.404C50.973,51.648,50.754,51.719,50.538,51.719z M45.071,50h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1
	h2c0.553,0,1,0.447,1,1S45.624,50,45.071,50z M39.071,50h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
	S39.624,50,39.071,50z M33.129,49.594c-0.139,0-0.28-0.029-0.415-0.091c-0.736-0.336-1.402-0.844-1.927-1.467
	c-0.355-0.423-0.301-1.054,0.122-1.409s1.053-0.301,1.409,0.122c0.334,0.397,0.759,0.721,1.228,0.936
	c0.502,0.229,0.723,0.822,0.493,1.325C33.871,49.377,33.509,49.594,33.129,49.594z M30.58,44.65c-0.063,0-0.126-0.006-0.189-0.018
	c-0.543-0.104-0.897-0.629-0.794-1.171c0.155-0.809,0.483-1.556,0.973-2.222c0.328-0.445,0.953-0.539,1.398-0.213
	c0.445,0.328,0.54,0.954,0.213,1.398c-0.313,0.424-0.521,0.899-0.619,1.413C31.469,44.317,31.05,44.65,30.58,44.65z M34.784,41.005
	c-0.533,0-0.977-0.421-0.998-0.959c-0.022-0.552,0.406-1.018,0.958-1.04C34.829,39.002,34.914,39,35,39h1.785c0.553,0,1,0.447,1,1
	s-0.447,1-1,1l-1.959,0.004C34.812,41.005,34.798,41.005,34.784,41.005z M53,41h-0.215c-0.553,0-1-0.447-1-1s0.447-1,1-1H53
	c0.469,0,0.923-0.091,1.352-0.271c0.505-0.215,1.095,0.025,1.309,0.535c0.214,0.509-0.025,1.095-0.535,1.309
	C54.451,40.856,53.736,41,53,41z M48.785,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S49.338,41,48.785,41z
	 M42.785,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S43.338,41,42.785,41z M57.318,37.77
	c-0.093,0-0.188-0.013-0.281-0.04c-0.53-0.155-0.834-0.711-0.679-1.241c0.094-0.32,0.142-0.652,0.142-0.988
	c0.002-0.192-0.014-0.374-0.042-0.552c-0.088-0.545,0.283-1.059,0.829-1.146c0.543-0.09,1.058,0.283,1.146,0.829
	c0.047,0.293,0.069,0.588,0.067,0.876c0,0.519-0.074,1.04-0.222,1.543C58.15,37.487,57.751,37.77,57.318,37.77z M55.25,32.604
	c-0.17,0-0.343-0.043-0.501-0.135c-0.453-0.263-0.947-0.417-1.47-0.458c-0.551-0.043-0.962-0.524-0.919-1.075
	c0.043-0.552,0.526-0.959,1.075-0.919c0.823,0.064,1.604,0.308,2.317,0.722c0.478,0.277,0.641,0.89,0.363,1.367
	C55.931,32.425,55.595,32.604,55.25,32.604z M49.357,32h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1
	S49.91,32,49.357,32z M43.357,32h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S43.91,32,43.357,32z M37.357,32h-2
	c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S37.91,32,37.357,32z M31.357,32H31c-0.678-0.001-1.337-0.123-1.963-0.361
	c-0.516-0.197-0.774-0.775-0.578-1.291c0.198-0.517,0.779-0.773,1.291-0.578c0.398,0.152,0.819,0.229,1.252,0.23h0.355
	c0.553,0,1,0.447,1,1S31.91,32,31.357,32z M26.724,28.905c-0.42,0-0.812-0.267-0.95-0.688c-0.182-0.552-0.273-1.13-0.273-1.718
	c0.001-0.238,0.016-0.469,0.044-0.694c0.068-0.546,0.552-0.931,1.117-0.867c0.548,0.068,0.937,0.569,0.867,1.117
	c-0.019,0.147-0.027,0.298-0.028,0.449c0,0.37,0.059,0.737,0.174,1.088c0.172,0.524-0.113,1.09-0.638,1.263
	C26.933,28.89,26.827,28.905,26.724,28.905z M28.628,23.676c-0.334,0-0.66-0.167-0.85-0.471c-0.292-0.469-0.149-1.086,0.319-1.378
	c0.7-0.437,1.472-0.703,2.294-0.794c0.553-0.057,1.043,0.336,1.104,0.885s-0.336,1.043-0.885,1.104
	c-0.521,0.058-1.011,0.227-1.454,0.503C28.991,23.627,28.809,23.676,28.628,23.676z M42.5,23h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2
	c0.553,0,1,0.447,1,1S43.053,23,42.5,23z M36.5,23h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S37.053,23,36.5,23z" />
              <path style="fill:#26B99A;" d="M53.47,2.669L53.47,2.669c-3.157-3.559-8.276-3.559-11.433,0v0c-3.157,3.559-3.157,9.328,0,12.887
	L47.753,22l5.716-6.444C56.627,11.998,56.627,6.228,53.47,2.669z M48,13c-2.485,0-4.5-2.015-4.5-4.5S45.515,4,48,4
	s4.5,2.015,4.5,4.5S50.485,13,48,13z" />
              <path style="fill:#3083C9;"
                d="M19.278,28.64c-4.296-4.853-11.261-4.853-15.556,0h0c-4.296,4.853-4.296,12.721,0,17.573L11.5,55
	l7.778-8.787C23.574,41.36,23.574,33.492,19.278,28.64z M11.5,41c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S14.261,41,11.5,41z" />
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
            </svg> </span>{{businessDetails.address}}</p>
      </div>

      <mat-accordion>
        <mat-expansion-panel class="business-drcp" *ngIf="businessDetails.aboutus != ''">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-bars" aria-hidden="true"></i> {{language.DESCRIPTION}}
            </mat-panel-title>

          </mat-expansion-panel-header>
          <p>{{businessDetails.aboutus}}</p>
        </mat-expansion-panel>

        <mat-expansion-panel class="business-drcp1" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-clock-o" aria-hidden="true"></i> {{language.OPEN}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <table>
              <tr *ngFor="let bdays of businessDays">
                <td>{{bdays.dayname}}</td>
                <td *ngIf="bdays.time1 == false">&nbsp;&nbsp;--:--</td>
                <td *ngIf="bdays.time1 == true">{{bdays.opens.hour}}:{{bdays.opens.minute}} -
                  {{bdays.closes.hour}}:{{bdays.closes.minute}}</td>
                <td *ngIf="bdays.time2 == false">&nbsp;&nbsp;--:--</td>
                <td *ngIf="bdays.time2 == true">{{bdays.opens2.hour}}:{{bdays.opens2.minute}} -
                  {{bdays.closes2.hour}}:{{bdays.closes2.minute}}</td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>
  <div class="col-md-6 col-xs-6 col-xs-12">
    <mat-card class="tb-dtls">
      <div class="contact-frm">
        <h5><span>
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
          </span>{{language.CONTACT_BUSINESS}}</h5>
        <div class="form-group">
          <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" [(ngModel)]="contactDetails.name" required
            #cuname>
        </div>
        <div class="form-group">
          <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="contactDetails.email" required
            #cuemail>
        </div>
        <div class="form-group">
          <textarea (keypress)="omit_special_char($event)" placeholder="{{language.MESSAGE}}" [(ngModel)]="contactDetails.message" required
            #cumessage></textarea>
        </div>
        <div class="form-group">
          <button class="cmn-button" (click)="contactUs()">{{language.SUBMIT}}</button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
