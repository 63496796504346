import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';

import { Router } from '@angular/router';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { OrderdetailsComponent } from '../orderdetails/orderdetails.component';
import { ReviewComponent } from '../review/review.component';
import { Globals } from '../globals';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  public globals: Globals;
  public language;
  public isOrderHistory: boolean;
  public userId: any;
  public OrderHistory: any = [];
  public searchText: any;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    public router: Router,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.dataSharingService.isOrderHistory.subscribe(value => {
      this.isOrderHistory = value;
      this.ngOnInit();
    });
    this.dataSharingService.iscancelledOrder.subscribe(value => {
      if (value) {
        this.ngOnInit();
        this.isOrderHistory = true;
      }
    });
  }

  ngOnInit() {
    if (this.isOrderHistory === true) {
      this.userId = this.comservice.getFromLocal('userId');
      this.apiservice.getOrderByUser((this.globals.APP_TYPE) ? 0 : this.globals.BUSINESS_ID, this.userId).subscribe(response => {
        this.OrderHistory = response;
      });
    }
  }
  viewDetails(order) {
    const orderdialogRef = this.dialog.open(OrderdetailsComponent, {
      width: '700px',
      data: {
        order: order
      },
      disableClose: true
    });

    orderdialogRef.afterClosed().subscribe(result => {});
  }
  openReview(order) {
    const reviewDialogRef = this.dialog.open(ReviewComponent, {
      width: '500px',
      data: {
        orderid: order.id
      },
      disableClose: true
    });

    reviewDialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  openReOrder(order) {
    this.spinner.show();
    let userdDtails;
    userdDtails = this.comservice.getFromLocal('user-details');
    let uid = -1;
    if (userdDtails) {
      if (userdDtails.id) {
        uid = userdDtails.id;
      }
    }
    this.apiservice.getBusiness(order.bslug, uid).subscribe(response => {
      this.spinner.hide();
      let is_img;
      is_img = JSON.parse(response.is_img);
      if (is_img.is_img === 1) {
        response.logo = is_img.data.secure_url;
      } else {
        response.logo = './assets/images/business_logo.png';
      }
      let is_banner;
      is_banner = JSON.parse(response.is_banner);
      if (is_banner.is_img === 1) {
        response.banner = is_banner.data.secure_url;
      } else {
        response.banner = './assets/images/business_banner.png';
      }
      response.minimumfee = response.minimumfeeback;

      response.street = JSON.parse(response.street);
      response.type = JSON.parse(response.type);
      response.location = JSON.parse(response.location);
      response.is_img = JSON.parse(response.is_img);
      response.is_banner = JSON.parse(response.is_banner);
      response.cuisine = JSON.parse(response.cuisine);

      let business;
      business = {
        'id': response.id,
        'details': response,
        'dish': new Array()
      };
      business.dish = order.dishdata;
      let CartDish;
      CartDish = new Array();
      CartDish.push(business);
      this.cartRemove();
      this.comservice.set('cartDetails', CartDish);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/' + CartDish[0].details.slug]);
      });
    });
  }
  cartRemove() {
    let CartDish;
    CartDish = new Array();
    this.comservice.set('cartDetails', CartDish);
    let orderType;
    orderType = '0';
    this.comservice.set('orderType', orderType);
    let preorderDetails;
    preorderDetails = {
      preorder: false,
      preorderDate: '',
      preorderTime: '',
      preorderMenu: 0,
      preorderMenuSchedule: '',
      preorderDateString: ''
    };
    this.comservice.set('preorderDetails', preorderDetails);
  }
}
