<div class="dtls-bnr">
  <div class="dtls-bnr-prlx prfl-bnr-prlx">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="business-nm">
            <div class="business-pic">
              <img [lazyLoad]="userdetails.img" />
              <span><i class="fa fa-camera" aria-hidden="true"></i>
                <input type='file' (change)="onSelectFile($event)">
              </span>
            </div>
            <div class="business-dtl">
              <h4>{{userdetails.name}} {{userdetails.last_name}}</h4>
              <p><span> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <g>
                        <path
                          d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z" />
                      </g>
                    </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                  </svg> </span>{{userdetails.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tab-dtls pd-tb-60">
  <div class="container">
    <mat-card class="tab-content" id="tab-content1">
      <div class="tab-pane tab-pane-profile active">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12">
            <ul>
              <li> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 1 }"
                  (click)="changeTab(1)">{{language.UPDATE_PROFILE}}</a>
              </li>
              <li> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 2 }"
                  (click)="changeTab(2)">{{language.CHANGE_PASSWORD}}</a>
              </li>
              <li *ngIf="!appType"> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 3 }"
                  (click)="changeTab(3)">{{language.FAVOURTIE_RESTAURANT}} </a>
              </li>
              <li> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 4 }"
                  (click)="changeTab(4)">{{language.ORDER_HISTORY}}</a>
              </li>
              <li> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 5 }"
                  (click)="changeTab(5)">{{language.BOOKING_HISTORY}}</a>
              </li>
              <li> <a href="javascript:void(0);" [ngClass]="{'active': tabChange == 6 }"
                  (click)="changeTab(6)">{{language.REWARD_HISTORY}}</a>
              </li>
              <li> <a href="javascript:void(0);" (click)="goToLogout()">{{language.LOG_OUT}}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-sm-8 col-xs-12">
            <div class="view-tab">
              <div class="edit" [hidden]="tabChange != 1">
                <h4>{{language.MY_PROFILE}}</h4>
                <div class="form-group">
                  <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME}}" [(ngModel)]="userdetails.name" required
                    #profile_name>
                </div>
                <div class="form-group">
                  <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.LAST_NAME}}" [(ngModel)]="userdetails.last_name"
                    required #profile_lname>
                </div>
                <div class="form-group">
                  <input autocomplete="off" placeholder="{{language.ADDRESS}}" (keyup)="PlaceChanged()"
                    autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="userdetails.address" required
                    #search>
                </div>
                <div class="form-group">
                  <input autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="userdetails.email" required
                    #profile_email>
                </div>
                <div class="form-group">
                  <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.MOBILE}}" [(ngModel)]="userdetails.cel" required
                    #profile_cel>
                </div>
                <div class="form-group">
                  <button class="cmn-button" (click)="updateUser()">{{language.SAVE_DETAILS}}</button>
                </div>
              </div>
              <ng-template #profileSubContainer1></ng-template>
              <ng-template #profileSubContainer2></ng-template>
              <ng-template #profileSubContainer3></ng-template>
              <ng-template #profileSubContainer4></ng-template>
              <ng-template #profileSubContainer5></ng-template>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
