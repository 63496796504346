<div class="order-history" *ngIf="this.isRewardHistory === true">
  <h4>{{language.REWARD_HISTORY}}</h4>

  <mat-card class="order-item" *ngIf="RewardHistory.length != 0">
    <mat-accordion>
      <mat-expansion-panel class="business-reward" *ngFor="let reward of RewardHistory"
        (opened)="reward.openpanel = true" (closed)="reward.openpanel = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="rwd-mat">
            <i class="fa fa-credit-card-alt" aria-hidden="true"></i> <span class="nameBusiness"
              *ngIf="reward.status == '1'" (click)="goToBusiness(reward)">{{reward.name}}</span><span
              class="nameBusiness notAvailable" *ngIf="reward.status == '0'">{{reward.name}}</span> ::
            <span>{{language.TOTAL_AVAILABLE_POINT}} - {{reward.totalAvailablePoint}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <table style="width: 100%;">
            <tr>
              <th style="width: 30%;">{{language.DATE}}</th>
              <th style="width: 50%;">{{language.REMARKS}}</th>
              <th style="width: 20%;">{{language.POINT}}</th>
            </tr>
            <tr *ngFor="let rData of reward.rewardData">
              <td [ngClass]="{'color-red': rData.type == '1'}">{{rData.date}}</td>
              <td [ngClass]="{'color-red': rData.type == '1'}">{{language.ORDER_ID}}: {{rData.orderId}} -
                {{language.PRICE}}: {{rData.price}}</td>
              <td [ngClass]="{'color-red': rData.type == '1'}">{{rData.point}} <span
                  *ngIf="rData.type == '1'">{{language.CREDIT}}</span><span
                  *ngIf="rData.type == '0'">{{language.DEBIT}}</span></td>
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>


  </mat-card>
  <mat-card class="no-item" *ngIf="RewardHistory.length == 0">
    <p>{{language.NO_REWARD_HISTORY}}</p>
  </mat-card>
</div>
