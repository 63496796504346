import { Component, ElementRef, Inject, ViewChild, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { isPlatformBrowser } from '@angular/common';

import { ApiService } from './../api.service';
import { Globals } from '../globals';

export interface DialogData {
  otp: any;
}

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent {
  public globals: Globals;
  public otpnumer: any;
  public language;

  @ViewChild('otpno', {static: false}) otpno: ElementRef;
  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef < OtpComponent >,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private spinner: NgxSpinnerService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
  }
  submitOtp() {
    if (this.otpnumer === '' || this.otpnumer === undefined) {
      this.snackBar.open(this.language.ENTER_OTP_VALID, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.otpno.nativeElement.focus();
      }
      return false;
    }
    if (this.otpnumer.length === 6) {
      this.spinner.show();
      this.apiservice.getOtpVerified(this.otpnumer, this.data.otp).subscribe(response => {
        this.spinner.hide();
        if (response) {
          this.dialogRef.close(true);
        } else {
          this.snackBar.open(this.language.NOT_MATCHED_CHECK_AGAIN, this.language.TOAST_OK, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      });
    } else {
      this.snackBar.open(this.language.NOT_MATCHED_CHECK_AGAIN, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.otpno.nativeElement.focus();
      }
    }
  }
}
