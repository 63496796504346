<div class="body-str">
  <div class="">
    <mat-card class="cupon">
      <div class="contact-cover">
        <h4 class="text-center">{{language.BOOK_A_TABLE}}</h4>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="sec-hdn">
              <span><i class="fa fa-calendar" aria-hidden="true"></i></span>
              <h4>{{language.BOOKING_DETAILS}}</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <input class="inp-mg" (keypress)="omit_special_char($event)" [(ngModel)]="bookDetails.bookingDate" autocomplete="off" matInput [min]="minDate"
                [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChange('change', $event)"
                placeholder="Choose a Date" (click)="picker.open()" (keydown)="false" required #pickerone>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <select [(ngModel)]="bookDetails.bookingTime" required #b_time>
                <option value="">{{language.SELECT_BOOKING_TIME}}</option>
                <option *ngFor="let opt of bookingTime" [value]="opt">{{opt}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="sec-hdn-bdr"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="sec-hdn">
              <span><i class="fa fa-user" aria-hidden="true"></i></span>
              <h4>{{language.USER_DETAILS}}</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" type="text" [(ngModel)]="bookDetails.name"
                required #b_name>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.EMAIL}}" type="text" [(ngModel)]="bookDetails.email"
                required #b_email>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <input autocomplete="off" placeholder="{{language.MOBILE}}" type="text" [(ngModel)]="bookDetails.cel"
                (keypress)="numberOnly($event)" required #b_cel>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-sm-12">
            <div class="form-group">
              <select [(ngModel)]="bookDetails.person" required>
                <option value="">{{language.PERSON_SELECT}}</option>
                <option *ngFor="let opt of personNumber" [value]="opt">{{opt}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-sm-12">
            <div class="form-group">
              <textarea (keypress)="omit_special_char($event)" [(ngModel)]="bookDetails.notes" placeholder="{{language.NOTE}}"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-sm-12">
            <div class="form-group text-center">
              <button class="cmn-button" (click)="CheckallBookingDetails()">{{language.BOOK_A_TABLE}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
