<div class="new-pop">
  <h1>{{language.ORDER_ID}} # {{orderDetails.id}} <small class="order_status">({{orderDetails.statustext}})</small></h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="order-bdy">
    <ngx-simplebar [options]="options">
      <h2 *ngIf="orderDetails.order_type == '1'">
        {{language.ESTIMATED_DELIVERY_TIME}} <span class="est-time">{{orderDetails.estimate_time}}</span>
      </h2>
      <h2 *ngIf="orderDetails.order_type == '2'">
        {{language.ESTIMATED_PICKUP_TIME}} <span class="est-time">{{orderDetails.estimate_time}}</span>
      </h2>
      <div class="sec-box">
        <div class="row ">
          <div class="col-md-6">
            <div class="mdl-dtls">
              <h4>{{language.FROM}}</h4>
              <h5>{{orderDetails.bname}}</h5>
              <p>{{orderDetails.baddress}}</p>
              <p>{{orderDetails.bmobile}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mdl-dtls">
              <h4>{{language.TO}}</h4>
              <h5>{{orderDetails.buyerdata.name}} {{orderDetails.buyerdata.last_name}}</h5>
              <p *ngIf="orderDetails.order_type == '1'">{{orderDetails.buyerdata.delievryaddress}}</p>
              <p *ngIf="orderDetails.order_type == '1'">{{orderDetails.buyerdata.specialaddress}}</p>
              <p>{{orderDetails.buyerdata.email}}</p>
              <p>{{orderDetails.buyerdata.cel}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-box">
        <div class="row">
          <div class="col-md-6">
            <h4>{{language.ORDER_DATE_TIME}} :</h4>
            <p>{{orderDetails.datetime}}</p>
          </div>
          <div class="col-md-6" *ngIf="orderDetails.laundryservice == '0'">
            <h4>{{language.PREORDER_DATE_TIME}} :</h4>
            <p *ngIf="orderDetails.buyerdata.preorder == true">{{orderDetails.buyerdata.preorderDate}} -
              {{orderDetails.buyerdata.preorderTime}}</p>
            <p *ngIf="orderDetails.buyerdata.preorder == false">{{language.ASAP}}</p>
          </div>
        </div>
      </div>
      <div class="sec-box">
        <div class="row" *ngIf="orderDetails.laundryservice == '1'">
          <div class="col-md-6">
            <h4>{{language.PICKUP_DATE_TIME}} :</h4>
            <p>{{orderDetails.laundryservicedetails.pickupDate}} {{orderDetails.laundryservicedetails.pickupTime}}</p>
          </div>
          <div class="col-md-6">
            <h4>{{language.DELIVERY_DATE_TIME}} :</h4>
            <p>{{orderDetails.laundryservicedetails.deliveryDate}} {{orderDetails.laundryservicedetails.deliveryTime}}
            </p>
          </div>
        </div>
      </div>
      <div class="row"
        *ngIf="orderDetails.comments !='' || orderDetails.drivercomments !='' || orderDetails.buyercomments !=''">
        <div class="col-md-6" *ngIf="orderDetails.comments !=''">
          <h4>{{language.COMMENTS}} :</h4>
          <p>{{orderDetails.comments}}</p>
        </div>
        <div class="col-md-6" *ngIf="orderDetails.drivercomments !=''">
          <h4>{{language.DRIVER_COMMENTS}} :</h4>
          <p>{{orderDetails.drivercomments}}</p>
        </div>
        <div class="col-md-6" *ngIf="orderDetails.buyercomments !=''">
          <h4>{{language.USER_COMMENTS}} :</h4>
          <p>{{orderDetails.buyercomments}}</p>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div *ngFor="let dishdata of orderDetails.dishdata">
            <div class="add-item" *ngFor="let dish of dishdata.data">
              <h6>{{dish.quantity}} X {{dish.name}}
                <span *ngIf="dish.spicy && dish.spicy === '1'">
                  <img *ngIf="dish.spicyquantity === '1'" [lazyLoad]="mirchiImage1" />
                  <img *ngIf="dish.spicyquantity === '2'" [lazyLoad]="mirchiImage2" />
                  <img *ngIf="dish.spicyquantity === '3'" [lazyLoad]="mirchiImage3" />
                </span>
                <span class="price-flt-ryt">{{orderDetails.currency_symbol}}{{dish.total}}</span>
              </h6>
              <div class="iten-add-on">
                <div class="iten-add-ons" *ngIf="dish.activeIngredients && dish.activeIngredients.length > 0">
                  <label class="add-svg"><span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;" xml:space="preserve">
                      <g>
                        <g>
                          <polygon
                            points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                        </g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                    </svg>
                  </span> {{language.INGREDIENTS}} - {{dish.activeIngredientsText}}</label>
                </div>
                <div class="iten-add-ons" *ngFor="let set of dish.sets">
                  <label class="add-svg"><span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;" xml:space="preserve">
                      <g>
                        <g>
                          <polygon
                            points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                        </g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                    </svg>
                  </span> {{set.name}} - {{set.choiceText}}</label>
                </div>

                <div class="iten-add-ons" *ngIf="dish.notes != ''">
                  <p>{{dish.notes}}</p>
                </div>

              </div>

            </div>
          </div>
          <div class="price-cht">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="confirmation_tbl">

              <tr>
                <td colspan="2">{{language.ORDER_TEMPLATE_SUB_TOTAL}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.subtotal}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.extraminimum > 0">
                <td colspan="2">{{language.EXTRA_CHARGE_MINIMUM}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.extraminimum}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.order_type == '1'">
                <td colspan="2">{{language.DELIEVRY_FEE}}</td>
                <td>
                  <strong
                    *ngIf="orderDetails.deliverycost > 0">{{orderDetails.currency_symbol}}{{orderDetails.deliverycost}}</strong>
                  <strong *ngIf="orderDetails.deliverycost == 0" class="delivery-free-text">{{language.FREE}}</strong>
                </td>
              </tr>
              <tr>
                <td colspan="2">{{language.ORDER_TEMPLATE_TAX}} ({{orderDetails.tax}}%)
                  <span *ngIf="orderDetails.taxtype == 0">{{language.TAX_NOT_INCLUDED}} </span>
                  <span *ngIf="orderDetails.taxtype == 1">{{language.TAX_INCLUDED}}</span>
                </td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.tax_price}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.servicefee_price > 0">
                <td colspan="2">{{language.SERVICE_FEE}} ({{orderDetails.servicefee}}%)</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.servicefee_price}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.tips > 0">
                <td colspan="2">{{language.TIPS}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.tips}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.discount > 0" class="clr-red">
                <td colspan="2">{{language.ORDER_TEMPLATE_DISCOUNT}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.discount}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.firstoffer > 0" class="clr-red">
                <td colspan="2">{{language.DISCOUNT_FOR_FIRST_ORDER}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.firstoffer}}</strong>
                </td>
              </tr>
              <tr [ngClass]="{'line-cut': orderDetails.reward_wallet == 1 }">
                <td colspan="2" class="total_text">{{language.ORDER_TEMPLATE_TOTAL}}</td>
                <td class="total_price_text">
                  <strong>
                    {{orderDetails.currency_symbol}}{{orderDetails.total}}
                  </strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.reward_wallet == 1" class="reward-cart">
                <td colspan="2" class="total_text">{{language.PAY_BY_REWARDS}}</td>
                <td class="total_price_text">
                  <strong>
                    {{orderDetails.currency_symbol}}{{orderDetails.reward_json.deductPrice}}
                  </strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.reward_wallet == 1" class="reward-cart">
                <td colspan="2" class="total_text">{{language.ORDER_TEMPLATE_TOTAL}}</td>
                <td class="total_price_text">
                  <strong>
                    {{orderDetails.currency_symbol}}{{orderDetails.reward_json.amountPrice}}
                  </strong>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ngx-simplebar>

  </div>

  <div class="flx-btn" *ngIf="cancelBtn">
    <button (click)="onCancelClick()" class="sbm-btn cmn-button cncl-btn">{{language.CANCEL_ORDER}}</button>
  </div>
</div>
