import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { CommonService } from '../common.service';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  public globals: Globals;
  public language;
  public txnId: any;
  public discountInfo: any;
  public rewardsData: any;
  public buyerDetails: any;
  public CartDish: any;
  public orderType: any;
  public Search: any;
  public preorderDetails: any;
  public deliveryDetails: any;
  public categoryDetails: any;
  public myClonedcategoryDetails;
  public MenuDetails: any;
  public businessDetails: any;
  public cartFee: any;
  public paymentMethod: any;
  public sourcetype: any;
  public sourcetypename: any;
  public extraJson: any = {};
  public postData: any;
  public orderId: any;
  public stripeSecretKey: any;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    public snackBar: MatSnackBar,
    public location: Location,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    let strOrder;
    strOrder = this.route.snapshot.paramMap.get('Id');
    let splitOrder;
    splitOrder = strOrder.split("MH")
    this.orderId = splitOrder[1];
    this.fectbackupOrder();
  }

  ngOnInit() {
    this.txnId = '';
    this.discountInfo = new Object();
    this.discountInfo = {
      'discounttext': '',
      'discounttype': '',
      'discountid': ''
    };
    this.rewardsData = new Object();
    this.buyerDetails = new Object();
  }
  fectbackupOrder() {
    this.apiservice.fetchbackuporderByPaymentIntent(this.orderId).subscribe(response => {
      let resultOrder;
      resultOrder = response;
      if (resultOrder.status) {
        this.comservice.setService('orderId', resultOrder.result);
        this.router.navigate(['/confirmation']);
        this.apiservice.deletebackuporder(this.orderId).subscribe(response => {

        });
      } else {
        this.snackBar.open('Order placed not successfully', this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate(['/']);
      }
    });
  }
  fectbackupOrder1() {
    this.apiservice.fetchbackuporder(this.orderId).subscribe(response => {
      let resultOrder;
      resultOrder = response.result;
      this.Search = JSON.parse(resultOrder.Search);
      this.buyerDetails = JSON.parse(resultOrder.buyerDetails);
      resultOrder.CartDish = resultOrder.CartDish.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
      // remove non-printable and other non-valid JSON chars
      resultOrder.CartDish = resultOrder.CartDish.replace(/[\u0000-\u0019]+/g,"");
      this.CartDish = JSON.parse(resultOrder.CartDish);
      this.cartFee = JSON.parse(resultOrder.cartFee);
      this.deliveryDetails = JSON.parse(resultOrder.deliveryDetails);
      this.discountInfo = JSON.parse(resultOrder.discountInfo);
      this.extraJson = JSON.parse(resultOrder.extraJson);
      this.paymentMethod = JSON.parse(resultOrder.paymentMethod);
      if (resultOrder.rewardWallet !== '') {
        this.rewardsData = JSON.parse(resultOrder.rewardWallet);
      }
      this.sourcetype = resultOrder.sourcetype;
      this.sourcetypename = JSON.parse(resultOrder.sourcetypename);
      let stripeResponse;
      stripeResponse = JSON.parse(resultOrder.stripeResponse);
      this.txnId = stripeResponse.payment_intent;

      let that = this;

      for (let index = 0; index < this.paymentMethod.length; index++) {
        const element = this.paymentMethod[index];
        if (element.id == '5') {
          this.stripeSecretKey = element.credential.secretkey;

          fetch(Globals.API_ENDPOINT_STRIPE_RETRIVE, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({
              id: stripeResponse.id,
              stripeSecretKey: this.stripeSecretKey
            }),
          })
          .then(function (resp) {
            console.log(resp);
            return resp.json();
          })
          .then(function (stripeRetieveResponse) {
            if (stripeRetieveResponse.payment_status === 'paid') {
              that.PlaceOrder(that.comservice, that.router);
            } else {

              let snackBarRef = that.snackBar.open('Payment not successfully.', that.language.TOAST_OK, {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
              snackBarRef.afterDismissed().subscribe(() => {
                that.router.navigate(['/'])
                .then(() => {
                  location.reload();
                });
              });
            }
          })
          .then(function (result) {
            console.log(result);
            // If redirectToCheckout fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using error.message.
            // alert(result);
          })
          .catch(function (error) {
            console.log(error);
            console.error('Error:', error);
          });
        }
      }
    });
  }
  PlaceOrder(comservice, router) {
    if (this.buyerDetails.name !== '') {
      this.buyerDetails.name = this.buyerDetails.name.replace(/'/g, "");
      this.buyerDetails.name = this.buyerDetails.name.replace(/\"/g, "");
    }
    if (this.buyerDetails.last_name !== '') {
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/'/g, "");
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/\"/g, "");
    }
    if (this.buyerDetails.notes !== '') {
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/'/g, "");
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/\"/g, "");
    }

    this.postData = {
      f: 'placeorder',
      langId: 1,
      userId: this.buyerDetails.id,
      CartDish: JSON.stringify(this.CartDish),
      cartFee: JSON.stringify(this.cartFee),
      BuyerDetails: JSON.stringify(this.buyerDetails),
      deliveryDetails: JSON.stringify(this.deliveryDetails),
      Search: JSON.stringify(this.Search),
      paymentMethod: JSON.stringify(this.paymentMethod),
      rewardWallet: JSON.stringify(this.rewardsData),
      sourcetype: this.sourcetype,
      sourcetypename: JSON.stringify(this.sourcetypename),
      discountInfo: JSON.stringify(this.discountInfo),
      txnId: this.txnId,
      extraJson: JSON.stringify(this.extraJson)
    };

    this.apiservice.deletebackuporder(this.orderId).subscribe(response => {

    });

    $.post(Globals.API_ENDPOINT_PLACE, this.postData, function (res) {
      this.orderId = res.placeorder[0];
      comservice.setService('orderId', this.orderId);
      router.navigate(['/confirmation']);
    });
  }

}
