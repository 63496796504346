<div class="srch-rsturent" *ngIf="this.isFavBusiness === true">
  <h4>{{language.SEARCH_BUSINESS}}</h4>
  <div class="form-group">
         <input autocomplete="off" [(ngModel)]="searchText" placeholder="{{language.SEARCH_BUSINESS}}............">
        <i class="fa fa-search" aria-hidden="true"></i>

  </div>
  <div class="restaurant">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-sm-lg-12 col-xs-12 col-xs-lg-12" *ngFor="let fav of favBusiness | filter : searchText" [ngClass]="{'disableFav': fav.status == '0'}">
        <div class="list-item">
          <div class="list-item-pic">
            <div class="over-lay"></div>
            <span *ngIf="fav.open" class="mat-open">{{language.OPEN}}</span>
            <span class="cls" *ngIf="!fav.open">{{language.CLOSE}}</span>
            <img [lazyLoad]="fav.banner" />
          </div>
          <div class="list-item-dtl">
            <div class="cmp-logo">
              <img [lazyLoad]="fav.logo" />
            </div>
            <h4 class="block-with-text no-hand" *ngIf="fav.status == '0'">{{fav.name}}</h4>
            <h4 class="block-with-text" *ngIf="fav.status == '1'" (click)="goToBusiness(fav)">{{fav.name}}</h4>
            <p class="cnt-itm">
         <span><i class="fa fa-phone" aria-hidden="true"></i></span>
              <a href="tel:+{{fav.mobile}}">{{fav.mobile}}</a>
            </p>
            <p class="cnt-itm">
              <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>{{fav.address}}</p>
              <div class="addopn">
                <button class="ctgry">
                  <i class="fa fa-cutlery" aria-hidden="true"></i>
                </button>
                <div class="right-adon">
                  <button  (click)="contactUs(fav);" class="contact">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </button>
                  <button  (click)="deleteFav(fav);" class="addtofav active">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                  </button>
                  <button  class="rtng">
                    <i class="fa fa-star" aria-hidden="true"></i><span class="rtng-txt">{{fav.ratings}}</span>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>


      <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="favBusiness.length == 0">
          <mat-card class="no-item" >
              <p>{{language.NO_FAVOURITE_BUSINESS_FOUND}}</p>
            </mat-card>
        </div>
    </div>
  </div>
</div>
