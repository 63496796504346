<div class="new-pop">
  <h1>{{notificationDetails.title}}</h1>
  <div class="form-group">
    <h4>{{notificationDetails.subtitle}}</h4>
  </div>
  <div class="form-group">
    <p class="al-cntr" *ngIf="notificationDetails.date_from != notificationDetails.date_to">
      {{notificationDetails.fromDateTime | date:'EEEE, MMMM d, y, h:mm a'}} {{language.TO_SMALL}}
      {{notificationDetails.toDateTime | date:'EEEE, MMMM d, y, h:mm a'}}</p>
    <p class="al-cntr" *ngIf="notificationDetails.date_from == notificationDetails.date_to">
      {{notificationDetails.fromDateTime | date:'EEEE, MMMM d, y, h:mm a'}} {{language.TO_SMALL}}
      {{notificationDetails.toDateTime | date:'h:mm a'}} </p>
    <p class="al-cntr">{{notificationDetails.description}}</p>
  </div>
  <div class="flx-btn" *ngIf="notificationDetails.closed == '0'">
    <button (click)="onNoClick()" class="sbm-btn cmn-button" cdkFocusInitial
      style=" width: 100%;">{{language.CLOSE}}</button>
  </div>
</div>
