<div class="new-pop">
  <h1>{{data.title}}</h1>
  <div class="form-group">
    <p class="al-cntr">{{data.note}}</p>
  </div>
  <div class="flx-btn" *ngIf="data.cancel != ''">
    <button [mat-dialog-close]="false" class="sbm-btn cmn-button cncl-btn" cdkFocusInitial>{{data.cancel}}</button>
    &nbsp;&nbsp;
    <button [mat-dialog-close]="true" class="sbm-btn cmn-button" cdkFocusInitial>{{data.ok}}</button>
  </div>
  <div class="flx-btn" *ngIf="data.cancel == ''">
    <button [mat-dialog-close]="true" class="sbm-btn cmn-button" cdkFocusInitial>{{data.ok}}</button>
  </div>
</div>
