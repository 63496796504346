import { Component, OnInit, ViewChildren, } from '@angular/core';
import { MatDialog,  MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { ContactComponent } from '../contact/contact.component';
import { Location, PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  public globals: Globals;
  public allBusiness = [];
  public allCuisine = [];
  public allCuisineIds = [];
  public allCategory = [];
  public allFilteredBusiness = [];
  public searchBusiness: any;
  public searchaddress: any;
  public prevroutelocal: any;
  public filterOption: any [];
  public userdetails: any;
  public searchText: any;
  public filterObject = {
    'order': [],
    'status': [],
    'cuisine': [],
    'cat': []
  };
  public orderType = [
    {
    'name': 'Delivery',
    'id': 1,
    'selected' : false
    },
    {
      'name': 'Pickup',
      'id': 2,
      'selected': false
    }
  ];
  public businessStatus = [
    {
      'name': 'Open',
      'id': 1,
      'selected': false
    },
    {
      'name': 'Close',
      'id': 0,
      'selected': false
    }
  ];
  public filterBusiness: any;
  public language;
  route: string;
  public buildingsImage = './assets/images/buildings.png';

  constructor(
    private spinner: NgxSpinnerService,
    public location: Location,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private comservice: CommonService,
    private apiservice: ApiService,
    public router: Router,
    public plocation: PlatformLocation,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    if (!this.globals.APP_TYPE) {
      this.router.navigate(['/' + this.globals.BUSINESS_SLUG]);
    }

    if (this.globals.LANG_ID !== 0) {
      this.orderType = [
        {
        'name': this.language.DELIVERY,
        'id': 1,
        'selected' : false
        },
        {
          'name': this.language.PICKUP,
          'id': 2,
          'selected': false
        }
      ];
      this.businessStatus = [
        {
          'name': this.language.OPEN,
          'id': 1,
          'selected': false
        },
        {
          'name': this.language.CLOSE,
          'id': 0,
          'selected': false
        }
      ];

    }
  }
  ngOnInit() {
    this.comservice.scrollTop();
    if (this.location.path() !== '') {
      this.route = this.location.path();
      this.route = this.route.substr(1);
      this.typeOfSearch();
    }
  }
  typeOfSearch() {
    this.spinner.show();
    let prevroute;
    prevroute = this.comservice.getService('previous-route');
    this.prevroutelocal = this.comservice.getFromLocal('previous-route');
    this.searchBusiness = this.comservice.getService('searchbusiness');

    if (this.route === 'list') {
      this.searchaddress = this.comservice.getFromLocal('searchaddress');
    } else {
      let routepath;
      routepath = this.route.split('/');
      this.searchaddress = {
        type: routepath[0],
        id: routepath[1],
        name: routepath[2]
      };
    }

    this.userdetails = this.comservice.getFromLocal('user-details');
    let uid = -1;
    if (this.userdetails) {
      if (this.userdetails.id) {
        uid = this.userdetails.id;
      }
    }
    this.apiservice.getBusinessByAddress(this.searchaddress, 0, uid, this.globals.LANG_ID).subscribe(res => {
      this.spinner.hide();
      if (res.length === 0) {
        this.snackBar.open(this.language.NO_BUSINESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate(['/']);
      } else {
        this.comservice.setService('searchbusiness', res);
        for (const i of res) {
          this.allBusiness.push(i);
        }
        this.showBusiness();
      }
    });
  }

  toggleShowDiv(divName: string) {
    $('.' + divName).slideToggle();
  }

  indexCategory(val) {
    for (const c of this.allCategory) {
      if (c.name.toLowerCase() === val.name.toLowerCase()) {
        return 1;
      }
    }
  }

  showBusiness() {
    for (const j of this.allBusiness) {
      for (const k of j.category) {
        let pp = 0;
        if (this.allCategory.length > 0) {
          for (const p of this.allCategory) {
            if (p.name.toLowerCase() === k.name.toLowerCase()) {
              pp = 1;
              p.ids.push(k.id);
            }
          }
        }
        if (pp !== 1) {
          this.allCategory.push(k);
        }
        if (this.allCategory.length === 0) {
          this.allCategory.push(k);
        }
        this.allCategory.sort(function (a, b) {
          let nameA;
          nameA = a.name.toLowerCase();
          let nameB;
          nameB = b.name.toLowerCase();
          if (nameA < nameB) { // sort string ascending
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // default return value (no sorting)
        });
      }
      for (const l of j.cuisinelist) {
        if (this.allCuisineIds.indexOf(l.id) === -1) {
          this.allCuisine.push(l);
          this.allCuisine.sort(function (a, b) {
            let nameA;
            nameA = a.name.toLowerCase();
            let nameB;
            nameB = b.name.toLowerCase();
            if (nameA < nameB) { // sort string ascending
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // default return value (no sorting)
          });
          this.allCuisineIds.push(l.id);
        }
      }
    }
    if (this.allBusiness[this.allBusiness.length - 1].count === 12) {
      if (this.route === 'list') {
        this.searchaddress = this.comservice.getFromLocal('searchaddress');
      } else {
        let routepath;
        routepath = this.route.split('/');
        this.searchaddress = {
          type: routepath[0],
          id: routepath[1],
          name: routepath[2]
        };
      }
      this.userdetails = this.comservice.getFromLocal('user-details');
      let uid = -1;
      if (this.userdetails) {
        if (this.userdetails.id) {
          uid = this.userdetails.id;
        }
      }
      this.apiservice.getBusinessByAddress(this.searchaddress, this.allBusiness[this.allBusiness.length - 1].lastid, uid, this.globals.LANG_ID).subscribe(res => {
        if (res.length !== 0) {
          for (const i of res) {
            this.allBusiness.push(i);
          }
          this.comservice.setService('searchbusiness', this.allBusiness);
          this.showBusiness();
        }
      });
    }
    for (const i of this.allBusiness) {
      let is_img;
      is_img = JSON.parse(i.is_img);
      if (is_img.is_img === 1) {
        i.logo = is_img.data.secure_url;
      } else {
        i.logo = './assets/images/business_logo.png';
      }
      let is_banner;
      is_banner = JSON.parse(i.is_banner);
      if (is_banner.is_img === 1) {
        i.banner = is_banner.data.secure_url;
      } else {
        i.banner = './assets/images/business_banner.png';
      }
    }
    this.applyFilter();
  }
  businessFilter(type, val) {
    if (type === 'order') {
      if (this.filterObject.order.indexOf(val) !== -1) {
        this.filterObject.order.splice(this.filterObject.order.indexOf(val), 1 );
      } else {
        this.filterObject.order.push(val);
      }
    }
    if (type === 'status') {
      if (this.filterObject.status.indexOf(val) !== -1) {
        this.filterObject.status.splice(this.filterObject.status.indexOf(val), 1);
      } else {
        this.filterObject.status.push(val);
      }
    }
    if (type === 'cat') {
      if (this.filterObject.cat.indexOf(val) !== -1) {
        this.filterObject.cat.splice(this.filterObject.cat.indexOf(val), 1);
      } else {
        this.filterObject.cat.push(val);
      }
    }
    if (type === 'cuisine') {
      if (this.filterObject.cuisine.indexOf(val) !== -1) {
        this.filterObject.cuisine.splice(this.filterObject.cuisine.indexOf(val), 1);
      } else {
        this.filterObject.cuisine.push(val);
      }
    }
  }
  applyFilter() {
    this.filterBusiness = this.comservice.getService('searchbusiness');
    this.comservice.setService('filteredbusiness', this.filterBusiness);
    if (this.filterObject.order.length !== 0) {
      this.allBusiness = [];
      for (const i of this.filterBusiness) {
        if (this.filterObject.order.indexOf(1) !== -1 && this.filterObject.order.indexOf(2) !== -1) {
          if (i.is_delivery === '1' && i.is_pickup === '1') {
            this.allBusiness.push(i);
          }
        } else if (this.filterObject.order.indexOf(1) !== -1 && this.filterObject.order.indexOf(2) === -1) {
          if (i.is_delivery === '1') {
            this.allBusiness.push(i);
          }
        } else if (this.filterObject.order.indexOf(1) === -1 && this.filterObject.order.indexOf(2) !== -1) {
          if (i.is_pickup === '1') {
            this.allBusiness.push(i);
          }
        } else {
          this.allBusiness.push(i);
        }
      }
      this.comservice.setService('filteredbusiness', this.allBusiness);
    }

    this.filterBusiness = this.comservice.getService('filteredbusiness');
    if (this.filterObject.status.length !== 0) {
      this.allBusiness = [];
      for (const i of this.filterBusiness) {
        if (this.filterObject.status.indexOf(1) === -1 && this.filterObject.status.indexOf(0) === -1) {
          this.allBusiness.push(i);
        } else {
          if (this.filterObject.status.indexOf(1) !== -1) {
            if (i.open === true) {
              this.allBusiness.push(i);
            }
          }
          if (this.filterObject.status.indexOf(0) !== -1) {
            if (i.open === false) {
              this.allBusiness.push(i);
            }
          }
        }
      }
      this.comservice.setService('filteredbusiness', this.allBusiness);
    }

    this.filterBusiness = this.comservice.getService('filteredbusiness');
    if (this.filterObject.cuisine.length !== 0) {
      this.allBusiness = [];
      for (const i of this.filterBusiness) {
        let counter = 0;
        for (const k of i.cuisinelist) {
          for (const j of this.allCuisine) {
            if (k.id === j.id && j.selected === true && counter === 0) {
              this.allBusiness.push(i);
              counter++;
            }
          }
        }
      }
      this.comservice.setService('filteredbusiness', this.allBusiness);
    }

    this.filterBusiness = this.comservice.getService('filteredbusiness');
    if (this.filterObject.cat.length !== 0) {
      this.allBusiness = [];
      let businessids;
      businessids = [];
      for (const j of this.allCategory) {
        if (j.selected === true) {
          for (const l of j.ids) {
            for (const i of this.filterBusiness) {
              for (const k of i.category) {
                if (k.id === l) {
                  if (businessids.indexOf(i.id) === -1) {
                    this.allBusiness.push(i);
                    businessids.push(i.id);
                  }
                }
              }
            }
          }
        }
      }
      this.comservice.setService('filteredbusiness', this.allBusiness);
    }
  }
  clearFilter() {
    this.filterBusiness = this.comservice.getService('searchbusiness');
    this.allBusiness = [];
    this.filterObject = {
      'order': [],
      'status': [],
      'cuisine': [],
      'cat': []
    };
    this.orderType = [
      {
      'name': this.language.DELIVERY,
      'id': 1,
      'selected' : false
      },
      {
        'name': this.language.PICKUP,
        'id': 2,
        'selected': false
      }
    ];
    this.businessStatus = [
      {
        'name': this.language.OPEN,
        'id': 1,
        'selected': false
      },
      {
        'name': this.language.CLOSE,
        'id': 0,
        'selected': false
      }
    ];
    for (const i of this.allCuisine) {
      i.selected = false;
    }

    for (const i of this.allCategory) {
      i.selected = false;
    }
    for (const i of this.filterBusiness) {
      this.allBusiness.push(i);
    }
  }
  openBusiness(val) {
    this.comservice.scrollTop();
    this.router.navigate(['/' + val]);
  }
  addToFav(val) {
    this.userdetails = this.comservice.getFromLocal('user-details');
    if (this.userdetails) {
      if (this.userdetails.id) {
        this.apiservice.setAddToFav(this.userdetails.id, val.id).subscribe(response => {
          if (response.status === true) {
            val.addtofav = true;
          } else {
            val.addtofav = false;
          }
        });
      } else {
        this.snackBar.open(this.language.LOGIN_FOR_FAV_BUSINESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    } else {
      this.snackBar.open(this.language.LOGIN_FOR_FAV_BUSINESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
  }
  contactUs(val): void {
    const dialogRef = this.dialog.open(ContactComponent, {
      width: '400px',
      data: {
        business: val
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
