import { Component, OnInit, HostListener } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementRef, PLATFORM_ID } from '@angular/core';
import { ViewChild, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  public globals: Globals;
  public isChangePassword: boolean;
  public userId: any;
  public userPass: any;
  oldhide = true;
  newhide = true;
  confirmhide = true;
  public language;

  @ViewChild('old_pass', {static: false}) old_pass: ElementRef;
  @ViewChild('new_pass', {static: false}) new_pass: ElementRef;
  @ViewChild('confirm_pass', {static: false}) confirm_pass: ElementRef;

  constructor(
    private comservice: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.dataSharingService.isChangePassword.subscribe(value => {
      this.isChangePassword = value;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.userId = this.comservice.getFromLocal('userId');
    this.userPass = {oldpass: '', newpass: '', confirmpass: ''};
  }
  changePassword() {
    if (this.userPass.oldpass.trim() === '') {
      this.snackBar.open(this.language.ENTER_CURRENT_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.old_pass.nativeElement.focus();
      }
      return false;
    } else if (this.userPass.newpass.trim() === '') {
      this.snackBar.open(this.language.ENTER_NEW_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.new_pass.nativeElement.focus();
      }
      return false;
    } else if (this.userPass.newpass.length < 6) {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD_LENGTH, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.new_pass.nativeElement.focus();
      }
      return false;
    } else if (this.userPass.confirmpass.trim() === '') {
      this.snackBar.open(this.language.ENTER_CONFIRM_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.confirm_pass.nativeElement.focus();
      }
      return false;
    } else if (this.userPass.confirmpass.trim() !== this.userPass.newpass.trim()) {
      this.snackBar.open(this.language.CONFIRM_PASSWORD_NOT_MATCHED, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.confirm_pass.nativeElement.focus();
      }
      return false;
    } else {
      this.apiservice.setChangePass(this.userPass, this.userId).subscribe(response => {
        if (response.status === false) {
          this.snackBar.open(this.language.CURRENT_PASSWORD_NOT_MATCHED_WITH_USER, this.language.TOAST_OK, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if (isPlatformBrowser(this.platformId)) {
            this.old_pass.nativeElement.focus();
          }
          return false;
        } else {
          this.snackBar.open(this.language.PASSWORD_SUCCESSFULLY_CHANGED, this.language.TOAST_OK, {
            duration: 200,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.ngOnInit();
        }
      });
    }
  }
  omit_special_char(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
