<section class="bdy-str1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <mat-card class="tb-dtls">
          <div class="contact-frm">
            <h5><span>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>{{language.CONTACT_BUSINESS}}</h5>
            <div class="form-group">
              <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" [(ngModel)]="contactDetails.name" required
                #cuname>
            </div>
            <div class="form-group">
              <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="contactDetails.email" required
                #cuemail>
            </div>
            <div class="form-group">
              <textarea (keypress)="omit_special_char($event)" placeholder="{{language.MESSAGE}}" [(ngModel)]="contactDetails.message" required
                #cumessage></textarea>
            </div>
            <div class="form-group">
              <button class="cmn-button" (click)="contactUs()">{{language.SUBMIT}}</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</section>
