<div class="row">
  <div class="col-md-6 col-sm-6 col-xs-12">
    <div class="rvw-grp">
      <div class="tb-dtls-mgn" *ngFor="let review of businessReviews">
        <mat-card class="review-item">
          <div class="rvw-rtng"> <span>5<small>/ {{review.average}}</small></span>
          </div>
          <div class="revw-pic">
            <img [lazyLoad]="review.img" />
          </div>
          <div class="revw-dtl">
            <h5>{{review.name}}</h5>
            <span>{{review.date}}</span>
            <p>{{review.comment}}</p>
          </div>
        </mat-card>
      </div>
      <button type="button" class="cmn-button" *ngIf="!loadMoreResponse && reviewResponse && businessReviews.length < countReview" (click)="loadMore()">
        {{language.LOAD_MORE}}..  <i class="fa fa-repeat" aria-hidden="true"></i>
      </button>
      <div class="tb-dtls-mgn" *ngIf="loadMoreResponse">
        <mat-card class="review-item">
          <div class="ph-item">
            <div class="ph-col-2 ctm-load">
              <div class="ph-avatar"></div>
            </div>
            <div>
              <div class="ph-row">
                <div class="ph-col-12"></div>
                <div class="ph-col-2"></div>
                <div class="ph-col-10 empty"></div>
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 big empty"></div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="tb-dtls-mgn" *ngIf="reviewResponse && businessReviews.length == 0">
        <mat-card class="no-item">
          <p>{{language.NO_REVIEW}}</p>
        </mat-card>
      </div>
    </div>

  </div>




  <div class="col-md-6 col-sm-6 col-xs-12">
    <div class="card-cover">
      <mat-card class="add-rvw">
        <h5>{{language.ADD_REVIEW}}</h5>
        <div class="form-group">
          <div class="add-rtng">
            <label>{{language.PRODUCT_QUALITY}}</label>
            <div class='rating-stars text-center'>
              <ul id='stars' class="Quality">
                <li class='star' (click)="reviewStar(1,1)" data-value='1'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(1,2)" data-value='2'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(1,3)" data-value='3'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(1,4)" data-value='4'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(1,5)" data-value='5'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="add-rtng">
            <label>{{language.PUCTUALITY}}</label>
            <div class='rating-stars text-center'>
              <ul id='stars' class="Punctuality">
                <li class='star' (click)="reviewStar(2,1)" data-value='1'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(2,2)" data-value='2'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(2,3)" data-value='3'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(2,4)" data-value='4'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(2,5)" data-value='5'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="add-rtng">
            <label>{{language.SERVICE}}</label>
            <div class='rating-stars text-center'>
              <ul id='stars' class="Service">
                <li class='star' (click)="reviewStar(3,1)" data-value='1'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(3,2)" data-value='2'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(3,3)" data-value='3'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(3,4)" data-value='4'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(3,5)" data-value='5'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="add-rtng">
            <label>{{language.FOOD_PACKING}}</label>
            <div class='rating-stars text-center'>
              <ul id='stars' class="packing">
                <li class='star' (click)="reviewStar(4,1)" data-value='1'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(4,2)" data-value='2'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(4,3)" data-value='3'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(4,4)" data-value='4'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
                <li class='star' (click)="reviewStar(4,5)" data-value='5'>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
            <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" [(ngModel)]="username" required #rename>
        </div>
        <div class="form-group">
            <input autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="useremail" required #reemail>
        </div>
        <div class="form-group">
            <textarea (keypress)="omit_special_char($event)" placeholder="{{language.MESSAGE}}" [(ngModel)]="usercomments" required #remessage></textarea>
        </div>
        <div class="form-group">
          <button class="cmn-button" (click)="addReview()">{{language.SUBMIT}}</button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
