import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.name.toString().toLowerCase().includes(searchText);
        });
    }
}

@Pipe({
    name: 'filterorder'
})
export class OrderFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.id.toString().toLowerCase().includes(searchText);
        });
    }
}

@Pipe({
    name: 'filterPayment'
})
export class PaymentFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            if (searchText === '1') {
                if (it.is_delivery === '1') {
                    return true;
                }
            } else if (searchText === '2') {
                if (it.is_pickup === '1') {
                    return true;
                }
            } else if (searchText === '0') {
                return true;
            }
        });
    }
}
