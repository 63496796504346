<div class="change-password" *ngIf="this.isChangePassword === true">
  <h4>{{language.CHANGE_PASSWORD}}</h4>
  <div class="form-group">
    <input autocomplete="off" (keypress)="omit_special_char($event)" placeholder="{{language.CURRENT_PASSWORD}}" [(ngModel)]="userPass.oldpass"
      [type]="oldhide ? 'password' : 'text'" required #old_pass>
    <i class="fa" [ngClass]="{'fa-eye': oldhide == false, 'fa-eye-slash': oldhide == true }" aria-hidden="true"
      (click)="oldhide = !oldhide"></i>
  </div>
  <div class="form-group">
    <input autocomplete="off" (keypress)="omit_special_char($event)" placeholder="{{language.NEW_PASSWORD}}" [(ngModel)]="userPass.newpass"
      [type]="newhide ? 'password' : 'text'" required #new_pass>
    <i class="fa" [ngClass]="{'fa-eye': newhide == false, 'fa-eye-slash': newhide == true }" aria-hidden="true"
      (click)="newhide = !newhide"></i>
  </div>
  <div class="form-group">
    <input autocomplete="off" (keypress)="omit_special_char($event)" placeholder="{{language.CONFIRM_PASSWORD}}" [(ngModel)]="userPass.confirmpass"
      [type]="confirmhide ? 'password' : 'text'" required #confirm_pass>
    <i class="fa" [ngClass]="{'fa-eye': confirmhide == false, 'fa-eye-slash': confirmhide == true }" aria-hidden="true"
      (click)="confirmhide = !confirmhide"></i>
  </div>
  <div class="form-group">
    <button class="cmn-button" (click)="changePassword()">{{language.SUBMIT}}</button>
  </div>
</div>
