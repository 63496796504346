import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class DataSharingService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isChangePassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isFavBusiness: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isOrderHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isBookingHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isRewardHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isCartAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isSingleBusiness: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public iscancelledOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLanguageList: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isFrontLanguage: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isRecoveryPageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isRegisterPageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
