import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ElementRef, Inject, ViewChild, PLATFORM_ID, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';

import { ApiService } from './../api.service';
import { Globals } from '../globals';

export interface DialogData {
  business: any;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent  {
  public globals: Globals;
  public isContactContent: any;
  public contactDetails: any = {name: '', email: '', message: ''};
  public language;

  @ViewChild('name', {static: false}) name: ElementRef;
  @ViewChild('email', {static: false}) email: ElementRef;
  @ViewChild('message', {static: false}) message: ElementRef;
  constructor(
    public dialogRef: MatDialogRef < ContactComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.isContactContent = data.business;
  }

  contactBusiness() {
    if (this.contactDetails.name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.name.nativeElement.focus();
      }
      return false;
    }
    if (this.contactDetails.email === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.email.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.contactDetails.email.indexOf('@');
    let dotpos;
    dotpos = this.contactDetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.contactDetails.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.email.nativeElement.focus();
      }
      return false;
    }
    if (this.contactDetails.message === '') {
      this.snackBar.open(this.language.ENTER_YOUR_MESSAGE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.message.nativeElement.focus();
      }
      return false;
    }

    this.apiservice.setContact(this.contactDetails, this.isContactContent.id).subscribe(res => {
      if (res.status) {
        this.snackBar.open(this.language.THANK_YOU_FOR_CONTACT_US, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.dialogRef.close();
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
