import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementRef, PLATFORM_ID } from '@angular/core';
import { ViewChild, Inject } from '@angular/core';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from './../api.service';
import { Globals } from '../globals';

export interface DialogData {
  id: any;
}


@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {
  public globals: Globals;
  public id: string;
  public forgot: any = {pass: '', confirmpass: '' };
  public hide = true;
  public conhide = true;
  public language;

  @ViewChild('npass', {static: false}) npass: ElementRef;
  @ViewChild('cpass', {static: false}) cpass: ElementRef;
  constructor(
    public dialogRef: MatDialogRef < RecoveryComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private spinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    public router: Router,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.id = data.id;
  }

  ngOnInit() {
    this.apiservice.fetchUserByMD5(this.id).subscribe(res => {
      if (res.status === true) {
        this.forgot.id = res.id;
      } else {
        let snackBarRef;
        snackBarRef = this.snackBar.open(this.language.NOT_A_VALID_LINK, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        snackBarRef.afterDismissed().subscribe((action) => {
          this.onNoClick();
        });
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
  updatePass() {
    if (this.forgot.pass === '') {
      this.snackBar.open(this.language.ENTER_NEW_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.npass.nativeElement.focus();
      }
      return false;
    }
    if (this.forgot.pass.length < 6) {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD_LENGTH, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.npass.nativeElement.focus();
      }
      return false;
    }
    if (this.forgot.confirmpass === '') {
      this.snackBar.open(this.language.ENTER_CONFIRM_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cpass.nativeElement.focus();
      }
      return false;
    }
    if (this.forgot.confirmpass !== this.forgot.pass) {
      this.snackBar.open(this.language.CONFIRM_PASSWORD_NOT_MATCHED_WITH_NEW, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cpass.nativeElement.focus();
      }
      return false;
    }

    this.apiservice.UpdatePass(this.forgot.id, this.forgot.pass).subscribe(res => {
      if (res.status === true) {
        let snackBarRef;
        snackBarRef = this.snackBar.open(this.language.PASSWORD_SUCCESSFULLY_CHANGED, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        snackBarRef.afterDismissed().subscribe((action) => {
          this.onNoClick();
        });
      }
    });
  }
}
