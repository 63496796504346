import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatDialog } from '@angular/material';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'app-fav',
  templateUrl: './fav.component.html',
  styleUrls: ['./fav.component.css']
})
export class FavComponent implements OnInit {
  public globals: Globals;
  public isFavBusiness: boolean;
  public userId: any;
  public favBusiness: any = [];
  public language;
  public searchText: any;

  constructor(
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    public dialog: MatDialog,
    public router: Router,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.dataSharingService.isFavBusiness.subscribe(value => {
      this.isFavBusiness = value;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    if (this.isFavBusiness === true) {
      this.userId = this.comservice.getFromLocal('userId');
      this.apiservice.getFavBusiness(this.userId).subscribe(response => {
        this.favBusiness = response;
        for (const i of this.favBusiness) {
          let is_img;
          is_img = JSON.parse(i.is_img);
          if (is_img.is_img === 1) {
            i.logo = is_img.data.secure_url;
          } else {
            i.logo = './assets/images/business_logo.png';
          }
          let is_banner;
          is_banner = JSON.parse(i.is_banner);
          if (is_banner.is_img === 1) {
            i.banner = is_banner.data.secure_url;
          } else {
            i.banner = './assets/images/business_banner.png';
          }
        }
      });
    }
  }
  deleteFav(val) {
    this.apiservice.deleteFavBusiness(val.id).subscribe(response => {
      if (response.status === true) {
        this.snackBar.open(response.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.ngOnInit();
      } else {
        this.snackBar.open(response.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    });
  }
  goToBusiness(val) {
    this.router.navigate(['/' + val.slug]);
  }
  contactUs(val): void {
    const dialogRef = this.dialog.open(ContactComponent, {
      width: '400px',
      data: {
        business: val
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
