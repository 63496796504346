import { Component, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { BusinessComponent } from './../business/business.component';
import { NotificationComponent } from '../notification/notification.component';
import { Globals } from '../globals';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.css']
})
export class DynamicComponent implements OnInit {
  public globals: Globals;
  aroute: string;
  userdetails: any;
  public businessDetails: any;
  public businessLoad = false;

  @ViewChild('subContainer1', { read: ViewContainerRef, static: false }) subContainer1: ViewContainerRef;
  @ViewChild('subContainer2', { read: ViewContainerRef, static: false }) subContainer2: ViewContainerRef;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private compFactoryResolver: ComponentFactoryResolver,
    public location: Location,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    if (location.path() !== '') {
      this.aroute = location.path();
      this.aroute = this.aroute.substr(1);
    }
  }

  ngOnInit() {
    if (this.aroute !== 'login') {
      this.fetchThePath(this.aroute);
    }
  }

  fetchThePath(pathstr) {
    let compFactory: ComponentFactory<any>;
    this.userdetails = this.comservice.getFromLocal('user-details');
    let uid = -1;
    if (this.userdetails) {
      if (this.userdetails.id) {
        uid = this.userdetails.id;
      }
    }
    if (!this.globals.APP_TYPE) {
      this.router.navigate(['']);
      return false;
    }

    if (pathstr.includes('?fbclid=')) {
      let pathStrSplit;
      pathStrSplit = pathstr.split('?fbclid=');
      pathstr = pathStrSplit[0];
      this.location.replaceState('/' + pathstr);
    }
    this.spinner.show();
    this.apiservice.getBusiness(pathstr, uid).subscribe(response => {
      this.spinner.hide();
      if (Object.keys(response).length === 0) {
        this.router.navigate(['/404']);
      } else {
        if (response.days_status || response.renew_activate === '0') {
          this.router.navigate(['/renewal']);
        } else {
          this.comservice.setService('businessID', response.id);
          let is_img;
          is_img = JSON.parse(response.is_img);
          if (is_img.is_img === 1) {
            response.logo = is_img.data.secure_url;
          } else {
            response.logo = './assets/images/business_logo.png';
          }
          let is_banner;
          is_banner = JSON.parse(response.is_banner);
          if (is_banner.is_img === 1) {
            response.banner = is_banner.data.secure_url;
          } else {
            response.banner = './assets/images/business_banner.png';
          }

          this.comservice.setService('businessDetails', response);
          this.businessDetails = response;
          if (this.businessDetails.notification) {
            const dishdialogRef = this.dialog.open(NotificationComponent, {
              width: '700px',
              data: {
                notification: this.businessDetails.notificationDetails
              },
              disableClose: true
            });

            dishdialogRef.afterClosed().subscribe(result => {
              if (result) {
              }
            });
          }
          let preorderDetails;
          preorderDetails = this.comservice.get('preorderDetails');
          if (preorderDetails === undefined || preorderDetails === '') {
            preorderDetails = {
              preorder: false,
              preorderDate: '',
              preorderTime: '',
              preorderMenu: 0
            };
          } else {
            if (preorderDetails.preorderBusiness !== response.id) {
              preorderDetails = {
                preorder: false,
                preorderDate: '',
                preorderTime: '',
                preorderMenu: 0,
                preorderMenuSchedule: '',
                preorderDateString: ''
              };
              this.comservice.set('preorderDetails', preorderDetails);
            }
          }

          let orderTypeStatus;
          orderTypeStatus = this.comservice.get('orderType');

          let orderType;
          if (orderTypeStatus === undefined || orderTypeStatus === '') {
            orderType = '0';
          } else {
            orderType = orderTypeStatus;
          }

          this.apiservice.getBusinessMenu(response.id, orderType, preorderDetails).subscribe(responseMenu => {
            this.comservice.setService('MenuDetails', responseMenu.menu);
            this.comservice.setService('categoryDetails', responseMenu.category);
            this.businessLoad = true;

            compFactory = this.compFactoryResolver.resolveComponentFactory(BusinessComponent);
            this.subContainer1.createComponent(compFactory);
          });
        }

      }
    });
  }
}
