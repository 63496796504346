import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent  {
  public globals: Globals;
  public language;
  public notFoundImage = './assets/images/page_not_found.png';

  constructor(
    public router: Router,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
  }
  goHome() {
    this.router.navigate(['']);
  }
}
