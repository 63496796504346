import { Component, OnInit, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatDialog } from '@angular/material';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { CityComponent } from '../city/city.component';
import { CuisineComponent } from '../cuisine/cuisine.component';
import { BusinessComponent } from '../business/business.component';
import { NotificationComponent } from '../notification/notification.component';
import { DataSharingService } from '../data-share.service';
import { RecoveryComponent } from '../recovery/recovery.component';
import { RegisterComponent } from '../register/register.component';
import { ReviewComponent } from '../review/review.component';
import { Globals } from '../globals';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

declare var google: any;
declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit, AfterViewInit {
  public globals: Globals;
  public langId;
  public language;
  public appType;
  public searchaddress;
  public searchflag = 0;
  public searchControl: any = {
    address: '',
    latitude: '',
    longitude: '',
    zipcode: '',
    zoom: ''
  };
  public zipcode;
  public format_data;
  public location: {
    latitude: number,
    longitude: number
  };
  public userdetails: any;
  public ipAddress: any;
  public ipAddressResp: any;
  public listCityList: any;
  public listCity = false;
  public listCuisineList: any;
  public listCuisine = false;
  public currentCntryCode: any;
  public cityList: any;

  public bannerImage = './assets/images/banner.jpg';
  public logoImage = './assets/images/logo.png';
  public registerImage = './assets/images/registation.png';
  public cityCuisineImage = './assets/images/loc-bg.png';
  public cityImage = './assets/images/target.svg';
  public cuisineImage = './assets/images/restaurant.svg';

  public businessLoad = false;

  @ViewChild('subContainer1', { read: ViewContainerRef, static: false}) subContainer1: ViewContainerRef;
  @ViewChild('search', {static: false }) public searchElement: ElementRef;
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private compFactoryResolver: ComponentFactoryResolver,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private comservice: CommonService,
    private apiservice: ApiService,
    private ngZone: NgZone,
    location: Location,
    private lctn: Location,
    private router: Router,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.langId = this.globals.LANG_ID;
    this.appType = this.globals.APP_TYPE;
    if (location.path() === '/recovery/' + this.route.snapshot.paramMap.get('Id')) {
      this.dataSharingService.isRecoveryPageLoaded.next(true);
      const recoveryDialogRef = this.dialog.open(RecoveryComponent, {
        width: '500px',
        data: {
          id: this.route.snapshot.paramMap.get('Id')
        },
        disableClose: true
      });
      recoveryDialogRef.afterClosed().subscribe(result => {
        this.router.navigate(['/']);
      });
    }

    if (location.path() === '/review/' + this.route.snapshot.paramMap.get('Id')) {
      this.apiservice.fetchLinkByMD5(this.route.snapshot.paramMap.get('Id')).subscribe(res => {
        if (res.status) {
          this.dataSharingService.isRecoveryPageLoaded.next(true);
          const reviewDialogRef = this.dialog.open(ReviewComponent, {
            width: '500px',
            data: {
              orderid: res.data.orderid
            },
            disableClose: true
          });
          reviewDialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/']);
          });
        } else {
          this.snackBar.open(this.language.NOT_A_VALID_LINK, this.language.TOAST_OK, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      });
    }

    if (location.path() === '/register') {
      this.dataSharingService.isRegisterPageLoaded.next(true);
      this.gotoRegister();
    }

    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      if (value) {
        let userdetails;
        userdetails = this.comservice.getFromLocal('user-details');

        if (userdetails !== undefined && userdetails !== null &&  userdetails !== '') {
          this.searchaddress = userdetails.address;
          this.searchControl.address = userdetails.location.address;
          this.searchControl.latitude = userdetails.location.lat;
          this.searchControl.longitude = userdetails.location.lng;
          this.searchControl.zoom = 12;
          this.searchflag = 1;
        }
      }
    });

    if (!this.globals.APP_TYPE) {
      this.singleBusiness();
    }

  }
  ngAfterViewInit() {
    if (this.globals.APP_TYPE) {
      this.apiservice.getAllCity(this.langId).subscribe(response => {
        this.listCityList = response;
        let countryCode;
        countryCode = this.comservice.get('country_code');
        if (countryCode === '' || countryCode === undefined) {
          this.http.get('https://jsonip.com').subscribe((ipOfNetwork) => {
            this.ipAddress =  ipOfNetwork['ip'];
            this.http.get('https://www.iplocate.io/api/lookup/' + this.ipAddress).subscribe((resip) => {
              this.ipAddressResp = resip;
              countryCode = this.ipAddressResp.country_code;
              this.comservice.set('country_code', countryCode);
              this.listCity = true;
              this.cityList = new Array();
              for (let cntry of this.listCityList.country) {
                if (cntry.gcode.toLowerCase() === countryCode.toLowerCase()) {
                  this.currentCntryCode = cntry.id;
                  this.cityList = cntry.city;
                }
              }
            });
          });
        } else {
          this.listCity = true;
          this.cityList = new Array();
          for (let cntry of this.listCityList.country) {
            if (cntry.gcode.toLowerCase() === countryCode.toLowerCase()) {
              this.currentCntryCode = cntry.id;
              this.cityList = cntry.city;
            }
          }
        }
      });


      this.apiservice.getAllCuisine(this.langId).subscribe(respc => {
        this.listCuisineList = respc;
        this.listCuisine = true;
      });
    }
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    if (!this.globals.APP_TYPE) {
      return false;
    }

    let userdetails;
    userdetails = this.comservice.getFromLocal('user-details');

    if (userdetails !== undefined && userdetails !== null &&  userdetails !== '') {
      this.searchaddress = userdetails.address;
      this.searchControl.address = userdetails.location.address;
      this.searchControl.latitude = userdetails.location.lat;
      this.searchControl.longitude = userdetails.location.lng;
      this.searchControl.zoom = 12;
      this.searchflag = 1;
    }



    this.comservice.scrollTop();
    if (this.globals.ADDRESS_RESTRICTION) {
      let countryCode;
      countryCode = this.comservice.get('countryCode');
      if (countryCode === '' || countryCode === undefined) {
        this.http.get('https://jsonip.com').subscribe((ipOfNetwork) => {
          this.ipAddress =  ipOfNetwork['ip'];
          this.http.get('https://www.iplocate.io/api/lookup/' + this.ipAddress).subscribe((resip) => {
            this.ipAddressResp = resip;
            let options;
            options = {componentRestrictions: { country: this.ipAddressResp.country_code }};
            this.comservice.set('country_code', this.ipAddressResp.country_code);
            this.comservice.set('countryCode', this.ipAddressResp.country_code);
            this.initAutoComplete(options);
          });
        });
      } else {
        let options;
        options = {componentRestrictions: { country: countryCode }};
        this.initAutoComplete(options);
      }
    } else {
      let options;
      options = {};
      this.initAutoComplete(options);
    }
  }
  initAutoComplete(options) {
    // set current position
    this.mapsAPILoader.load().then(
      () => {
        // types: [], componentRestrictions: { country: 'IN' }
        let autocomplete;
        autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, options);

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.searchflag = 1;
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            } else {
              for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                  if (place.address_components[i].types[j] === 'postal_code') {
                    this.searchControl.zipcode = place.address_components[i].long_name;
                  }
                }
              }
              this.searchControl.address = place.formatted_address;
              this.searchControl.latitude = place.geometry.location.lat();
              this.searchControl.longitude = place.geometry.location.lng();
              this.searchControl.zoom = 12;
              this.SearchNow();
            }
          });
        });
      }
    );
  }
  PlaceChanged() {
    this.searchflag = 0;
  }
  preSearchNow() {
    if (this.searchflag === 1) {
      this.SearchNow();
    } else {
      let event;
      event = this;
      let firstResult;
      firstResult = $('.pac-container .pac-item:first').text();
      if (firstResult !== '') {
        let searchAddress;
        searchAddress = {
          address: '',
          latitude: '',
          longitude: '',
          zipcode: '',
          zoom: ''
        };
        let geocoder;
        geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': firstResult }, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            for (let i = 0; i < results[0].address_components.length; i++) {
              for (let j = 0; j < results[0].address_components[i].types.length; j++) {
                if (results[0].address_components[i].types[j] === 'postal_code') {
                  searchAddress.zipcode = results[0].address_components[i].long_name;
                }
              }
            }
            searchAddress.address = results[0].formatted_address;
            searchAddress.latitude = results[0].geometry.location.lat();
            searchAddress.longitude = results[0].geometry.location.lng();
            searchAddress.zoom = 12;
            event.searchControl = searchAddress;
            event.searchflag = 1;
            event.SearchNow();
          }
        });
      } else {
        this.snackBar.open(this.language.ENTER_CORRECT_ADDRESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return;
      }
    }
  }
  SearchNow() {
    if (this.searchflag === 1) {
      this.comservice.scrollDown();
      this.comservice.setService('searchaddress', this.searchControl);
      this.comservice.saveInLocal('searchaddress', this.searchControl);
      this.comservice.saveInLocal('previous-route', 'home');
      this.router.navigate(['/list']);
    } else {
      this.snackBar.open(this.language.ENTER_CORRECT_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
  }
  changeCountry(cntryVal) {
    this.cityList = new Array();
    for (let cntry of this.listCityList.country) {
      if (cntry.id === cntryVal) {
        this.comservice.set('country_code', cntry.gcode);
        this.cityList = cntry.city;
      }
    }
  }
  singleBusiness() {
    let compFactory: ComponentFactory<any>;
    this.userdetails = this.comservice.getFromLocal('user-details');
    let uid = -1;
    if (this.userdetails) {
      if (this.userdetails.id) {
        uid = this.userdetails.id;
      }
    }
    this.apiservice.getBusiness(this.globals.BUSINESS_SLUG, uid).subscribe(response => {
      if (Object.keys(response).length === 0) {
        this.router.navigate(['/404']);
      } else {
        this.comservice.setService('businessID', response.id);
        let is_img;
        is_img = JSON.parse(response.is_img);
        if (is_img.is_img === 1) {
          response.logo = is_img.data.secure_url;
        } else {
          response.logo = './assets/images/business_logo.png';
        }
        let is_banner;
        is_banner = JSON.parse(response.is_banner);
        if (is_banner.is_img === 1) {
          response.banner = is_banner.data.secure_url;
        } else {
          response.banner = './assets/images/business_banner.png';
        }
        this.comservice.setService('businessDetails', response);
        if (response.notification) {
          const dishdialogRef = this.dialog.open(NotificationComponent, {
            width: '700px',
            data: {
              notification: response.notificationDetails
            },
            disableClose: true
          });

          dishdialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
          });
        }


        let preorderDetails;
        preorderDetails = this.comservice.get('preorderDetails');
        if (preorderDetails === undefined || preorderDetails === '') {
          preorderDetails = {
            preorder: false,
            preorderDate: '',
            preorderTime: '',
            preorderMenu: 0
          };
        } else {
          if (preorderDetails.preorderBusiness !== response.id) {
            preorderDetails = {
              preorder: false,
              preorderDate: '',
              preorderTime: '',
              preorderMenu: 0,
              preorderMenuSchedule: '',
              preorderDateString: ''
            };
            this.comservice.set('preorderDetails', preorderDetails);
          }
        }

        let orderTypeStatus;
        orderTypeStatus = this.comservice.get('orderType');

        let orderType;
        if (orderTypeStatus === undefined || orderTypeStatus === '') {
          orderType = '0';
        } else {
          orderType = orderTypeStatus;
        }

        this.apiservice.getBusinessMenu(response.id, orderType, preorderDetails).subscribe(responseMenu => {
          this.comservice.setService('MenuDetails', responseMenu.menu);
          this.comservice.setService('categoryDetails', responseMenu.category);
          this.businessLoad = true;

          compFactory = this.compFactoryResolver.resolveComponentFactory(BusinessComponent);
          this.subContainer1.createComponent(compFactory);
        });



        // compFactory = this.compFactoryResolver.resolveComponentFactory(BusinessComponent);
       // this.subContainer1.createComponent(compFactory);
      }
    });
  }

  cityBrowse(): void {
    const dialogRef = this.dialog.open(CityComponent, {
      width: '800px',
      data: {
        city: this.cityList
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  cuisineBrowse(): void {
    const dialogRef = this.dialog.open(CuisineComponent, {
      width: '800px',
      data: {
        cuisines: this.listCuisineList.cuisines
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  pregotoRegister() {
    this.lctn.replaceState('/register');
    this.gotoRegister();
  }

  gotoRegister() {
    this.comservice.scrollTop();
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '800px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.lctn.replaceState('/');
    });
  }
  clearStorage() {
    this.comservice.removeFromLocal('Search');
    this.comservice.removeFromLocal('businessID');
    this.comservice.removeFromLocal('businessDetails');
    this.comservice.removeFromLocal('cartDetails');
    this.comservice.removeFromLocal('categoryDetails');
    this.comservice.removeFromLocal('myClonedcategoryDetails');
    this.comservice.removeFromLocal('MenuDetails');
    this.comservice.removeFromLocal('orderType');
  }
}
