import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'app-rewardhistory',
  templateUrl: './rewardhistory.component.html',
  styleUrls: ['./rewardhistory.component.css']
})
export class RewardhistoryComponent implements OnInit {
  public globals: Globals;
  public isRewardHistory: boolean;
  public userId: any;
  public RewardHistory: any = [];
  public language;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.dataSharingService.isRewardHistory.subscribe(value => {
      this.isRewardHistory = value;
      this.ngOnInit();
    });
  }
  ngOnInit() {
    if (this.isRewardHistory === true) {
      this.userId = this.comservice.getFromLocal('userId');
      this.apiservice.getRewardByUser((this.globals.APP_TYPE) ? 0 : this.globals.BUSINESS_ID, this.userId).subscribe(response => {
        this.RewardHistory = response;
      });
    }
  }
  goToBusiness(val) {
    this.router.navigate(['/' + val.slug]);
  }
}
