import 'hammerjs';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes, Router, PreloadAllModules } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { OwlModule } from 'ngx-owl-carousel';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';

import {MatBadgeModule} from '@angular/material/badge';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Meta, Title } from '@angular/platform-browser';
import { SimplebarAngularModule } from 'simplebar-angular';

import { Globals } from './globals';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { CommonService } from './common.service';
import { ApiService } from './api.service';
import { ListComponent } from './list/list.component';
import { FilterPipe, OrderFilterPipe, PaymentFilterPipe } from './filter.pipe';
import { Keyobject } from './object.pipe';
import { DynamicComponent } from './dynamic/dynamic.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { BusinessComponent } from './business/business.component';
import { CmsComponent } from './cms/cms.component';
import { InfoComponent } from './info/info.component';
import { PhotoComponent } from './photo/photo.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { OffersComponent } from './offers/offers.component';
import { LoginComponent } from './login/login.component';
import { DataSharingService } from './data-share.service';
import { DishComponent } from './dish/dish.component';
import { WhereruComponent } from './whereru/whereru.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfiramtionComponent } from './confiramtion/confiramtion.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ProfileComponent } from './profile/profile.component';
import { FavComponent } from './fav/fav.component';
import { OrderComponent } from './order/order.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { ContactComponent } from './contact/contact.component';
import { PreorderComponent } from './preorder/preorder.component';
import { OtpComponent } from './otp/otp.component';
import { BookingComponent } from './booking/booking.component';
import { BookinglistComponent } from './bookinglist/bookinglist.component';
import { BookingdetailsComponent } from './bookingdetails/bookingdetails.component';
import { FirstdataComponent } from './firstdata/firstdata.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RewardhistoryComponent } from './rewardhistory/rewardhistory.component';
import { CityComponent } from './city/city.component';
import { CuisineComponent } from './cuisine/cuisine.component';
import { SuggestionComponent } from './suggestion/suggestion.component';
import { RequiredminimumComponent } from './requiredminimum/requiredminimum.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { NotificationComponent } from './notification/notification.component';
import { AlertComponent } from './alert/alert.component';

import { ReviewComponent } from './review/review.component';
import { RenewalComponent } from './renewal/renewal.component';
import { PaymentComponent } from './payment/payment.component';



const appRoutes: Routes = [{
    path: '',
    component: HomeComponent,
    data: {preload: true}
  },
  {
    path: 'list',
    component: ListComponent,
    data: {preload: true}
  },
  {
    path: 'city/:Id/:Id',
    component: ListComponent,
    data: {preload: true}
  },
  {
    path: 'cuisine/:Id/:Id',
    component: ListComponent,
    data: {preload: true}
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'checkout/:Id',
    component: CheckoutComponent
  },
  {
    path: 'payment/:Id',
    component: PaymentComponent
  },
  {
    path: 'confirmation',
    component: ConfiramtionComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path: 'recovery/:Id',
    component: HomeComponent
  },
  {
    path: 'review/:Id',
    component: HomeComponent
  },
  {
    path: 'register',
    component: HomeComponent
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: 'renewal',
    component: RenewalComponent
  },
  {
    path: ':Id',
    component: DynamicComponent,
    data: {
      title: 'menuhust - business',
      description: 'menuhust - business',
      keywords: 'menuhust - business'
  }
  }];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    ListComponent,
    FilterPipe,
    OrderFilterPipe,
    PaymentFilterPipe,
    Keyobject,
    DynamicComponent,
    NotfoundComponent,
    BusinessComponent,
    CmsComponent,
    InfoComponent,
    PhotoComponent,
    ReviewsComponent,
    OffersComponent,
    LoginComponent,
    DishComponent,
    WhereruComponent,
    CheckoutComponent,
    ConfiramtionComponent,
    RecoveryComponent,
    ProfileComponent,
    FavComponent,
    OrderComponent,
    ChangepasswordComponent,
    OrderdetailsComponent,
    ContactComponent,
    PreorderComponent,
    OtpComponent,
    BookingComponent,
    BookinglistComponent,
    BookingdetailsComponent,
    FirstdataComponent,
    ContactusComponent,
    RewardhistoryComponent,
    CityComponent,
    CuisineComponent,
    SuggestionComponent,
    RequiredminimumComponent,
    NotificationComponent,
    AlertComponent,
    ReviewComponent,
    RenewalComponent,
    PaymentComponent,
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    HttpModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules}),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwWmYexCDWu-rTj2J2ngCje4JP0MFnYSk',
      libraries: ['places']
    }),
    AgmDirectionModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset
    }),
    OwlModule,
    DeviceDetectorModule.forRoot(),
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    ShareButtonsModule,
    SimplebarAngularModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatSliderModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatNativeDateModule
  ],
  exports: [
    MatCheckboxModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatSliderModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [
    Globals,
    CommonService,
    ApiService,
    DataSharingService,
    Meta,
    Title
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BusinessComponent,
    LoginComponent,
    AlertComponent,
    FirstdataComponent,
    BookinglistComponent,
    BookingdetailsComponent,
    BookingComponent,
    PreorderComponent,
    WhereruComponent,
    DishComponent,
    CmsComponent,
    InfoComponent,
    PhotoComponent,
    ReviewsComponent,
    OffersComponent,
    ProfileComponent,
    FavComponent,
    OrderComponent,
    ChangepasswordComponent,
    OrderdetailsComponent,
    ContactComponent,
    OtpComponent,
    RequiredminimumComponent,
    RewardhistoryComponent,
    CuisineComponent,
    CityComponent,
    SuggestionComponent,
    NotificationComponent,
    RecoveryComponent,
    RegisterComponent,
    ReviewComponent
  ]
})


export class AppModule {
  constructor(
    private router: Router,
    private comservice: CommonService
  ) {
    let APP_VERSION;
    APP_VERSION = '4.0.3';
    if (this.comservice.get('APP_VERSION') === undefined || this.comservice.get('APP_VERSION') !== APP_VERSION) {
      this.comservice.clear();
      this.comservice.set('APP_VERSION', APP_VERSION);
    }
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['404']); // or redirect to default route
    };
  }
}
