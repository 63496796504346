<div class="new-pop">
  <h1>{{language.FIRST_DATA}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="first-bdy">
    <form style="display:none;" id="firstdataform" method="post" target="iframe_a" action="https://www.ipg-online.com/connect/gateway/processing">
      <input type="hidden" name="txntype" value="sale">
      <input type="hidden" name="timezone" value="{{data.timezone}}"/>
      <input type="hidden" name="txndatetime" value="{{data.txndatetime}}"/>
      <input type="hidden" name="hash" value="{{data.hash}}"/>
      <input type="hidden" name="storename" value="{{data.storename}}"/>
      <input type="hidden" name="mode" value="fullpay"/>
      <input type="text"   name="chargetotal" value="{{data.chargetotal}}"/>
      <input type="hidden" name="currency" value="{{data.currency}}"/>
      <input type="text"   name="responseSuccessURL" value="{{data.successurl}}" />
      <input type="text"   name="responseFailURL" value="{{data.errorurl}}" />
      <input type="submit" value="Submit">
    </form>
  <div class="full-screen-preview__frame" [style.height.px]="myInnerHeight">
    <iframe id="iframe" src="" width="100%" [style.height.px]="myInnerHeight" name="iframe_a" frameborder="0" data-view="fullScreenPreview"></iframe>
    </div>
  </div>
</div>
