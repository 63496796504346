<div class="new-pop">
  <h1>{{language.CHOOSE_CUISINE}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls"><svg id="Capa_1"
      enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg></a>
  <div class="">
    <div class="src-cty">
      <span><i class="fa fa-search" aria-hidden="true"></i></span>
      <input type="text" name="text" [(ngModel)]="searchText" placeholder="Search Cuisine....." />
      <a href="javascript:void(0);"></a>
    </div>
    <ngx-simplebar [options]="options">
      <div class="city">
        <ul>
          <li *ngFor="let cuisine of isCuisineContent | filter : searchText">
            <a href="/cuisine/{{cuisine.id}}/{{cuisine.name}}">
              <span>
                <img [lazyLoad]="cuisineImage" />
              </span>{{cuisine.name}}
            </a>
          </li>
        </ul>
      </div>
    </ngx-simplebar>
  </div>
</div>
