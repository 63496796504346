import 'hammerjs'
import './icons';
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppBrowserModule } from '.././src/app/app.browser.module'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
                    platformBrowserDynamic()
                      .bootstrapModule(AppBrowserModule)
                      .catch(err => console.log(err));
                  });

