<div class="new-pop">
  <h1>{{language.ADD_REVIEW}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="form-group">
    <div class="add-rtng">
      <label>{{language.PRODUCT_QUALITY}}</label>
      <div class='rating-stars text-center'>
        <ul id='stars' class="Quality">
          <li class='star' (click)="reviewStar(1,1)" data-value='1'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(1,2)" data-value='2'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(1,3)" data-value='3'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(1,4)" data-value='4'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(1,5)" data-value='5'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="add-rtng">
      <label>{{language.PUCTUALITY}}</label>
      <div class='rating-stars text-center'>
        <ul id='stars' class="Punctuality">
          <li class='star' (click)="reviewStar(2,1)" data-value='1'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(2,2)" data-value='2'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(2,3)" data-value='3'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(2,4)" data-value='4'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(2,5)" data-value='5'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="add-rtng">
      <label>{{language.SERVICE}}</label>
      <div class='rating-stars text-center'>
        <ul id='stars' class="Service">
          <li class='star' (click)="reviewStar(3,1)" data-value='1'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(3,2)" data-value='2'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(3,3)" data-value='3'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(3,4)" data-value='4'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(3,5)" data-value='5'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="add-rtng">
      <label>{{language.FOOD_PACKING}}</label>
      <div class='rating-stars text-center'>
        <ul id='stars' class="packing">
          <li class='star' (click)="reviewStar(4,1)" data-value='1'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(4,2)" data-value='2'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(4,3)" data-value='3'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(4,4)" data-value='4'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
          <li class='star' (click)="reviewStar(4,5)" data-value='5'>
            <i class="fa fa-star" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" [(ngModel)]="username" required #rename>

  </div>
  <div class="form-group">

    <input autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="useremail" required #reemail>

  </div>
  <div class="form-group">

    <textarea (keypress)="omit_special_char($event)" placeholder="{{language.MESSAGE}}" [(ngModel)]="usercomments" required #remessage></textarea>

  </div>
  <div class="form-group">
    <button class="cmn-button" (click)="addReview()">{{language.SUBMIT}}</button>
  </div>
</div>
