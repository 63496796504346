<mat-dialog-content class="mat-typography required-minimum">
  <h5>{{language.MINIMUM_ORDER_RESTAURANT}} {{businessDetails.currency_symbol}}{{minimumPrice}}
  </h5>
  <p>{{language.TOTAL_OF_SELECTED_ITEMS}} <small>{{businessDetails.currency_symbol}}{{subtotal}} </small></p>
  <div class="flx-dv">
    <button (click)="onNoClick()">{{language.CHOOSE_MORE_ITEMS}}</button>
    <span>{{language.OR}}</span>
    <button (click)="addToExtraCharged()">{{language.JUST_CHARGE_ME_EXTRA}}
      {{businessDetails.currency_symbol}}{{chargedAmount}}</button>
  </div>
</mat-dialog-content>
