<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="businessPhoto.length > 0">
    <div class="">
      <mat-card class="gallery">
        <h5><span>
            <i class="fa fa-picture-o" aria-hidden="true"></i></span>Gallery</h5>
        <div class="gallery-theme">
          <ul>
            <li class="gallery-item" *ngFor="let photo of businessPhoto">
              <a data-fancybox="gallery" href="{{photo}}">
                <img [lazyLoad]="photo" />
              </a>
            </li>
          </ul>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="photoResponse && businessPhoto.length == 0">
    <div>
      <mat-card class="no-item">
        <p>{{language.NO_PHOTO}}</p>
      </mat-card>
    </div>
  </div>
</div>
