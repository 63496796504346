import { Component, Inject, NgZone, ViewChild, ElementRef , OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

export interface DialogData {
  business: any;
}
@Component({
  selector: 'app-whereru',
  templateUrl: './whereru.component.html',
  styleUrls: ['./whereru.component.css']
})
export class WhereruComponent implements OnInit {
  public globals: Globals;
  public latitude: any;
  public longitude: any;
  public zoom: any = 15;
  public label = 'Hi, infowindow';
  public draggable = true;
  public businessDetails: any;
  public currentLocation: any;
  public color = '#FF0000';
  public paths: any;
  public allkms: any;
  public zonematch = false;
  public zoneflag = false;
  public deliveryDetails = {
    status: false,
    type: '',
    details: new Object(),
    bid: 0,
    order: '',
    Search: ''
  };
  public Search: any;
  public zipval = '0';
  public searchaddress: any;
  public language;
  public ipAddress: any;
  public ipAddressResp: any;

  @ViewChild('search', {static: false})  public searchElementRef: ElementRef;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef < WhereruComponent >,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.businessDetails = data.business;

    if (this.businessDetails.search_type === '1') {
      this.paths = new Array();
      this.allkms = new Array();

      let location;
      location = JSON.parse(this.businessDetails.location);
      this.allkms = this.businessDetails.km;
      for (const path of this.businessDetails.zone) {
        let zone;
        zone = new Array();
        for (const z of path.zone) {
          let latlng;
          latlng = {
            'lat': z.latitud,
            'lng': z.longitud,
          };
          zone.push(latlng);
        }
        this.paths.push(zone);
      }
    }
  }
  ngOnInit() {
    if (this.businessDetails.search_type === '1') {
      if (this.globals.ADDRESS_RESTRICTION) {
        this.http.get('https://jsonip.com').subscribe((ipOfNetwork) => {
          this.ipAddress =  ipOfNetwork['ip'];
          this.http.get('https://www.iplocate.io/api/lookup/' + this.ipAddress).subscribe((resip) => {
            this.ipAddressResp = resip;
            let options;
            options = {componentRestrictions: { country: this.ipAddressResp.country_code }};
            this.initAutoComplete(options);
          });
        });
      } else {
        let options;
        options = {};
        this.initAutoComplete(options);
      }
    }
  }
  initAutoComplete(options) {
    this.setCurrentPosition();
    this.mapsAPILoader.load().then(() => {
      let autocomplete;
      autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
          this.searchaddress = place.formatted_address;
          this.getCurrentLocation();
          this.checkDelivery();
        });
      });
    });
  }
  setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getCurrentLocation();
      });
    }
  }
  getCurrentLocation() {
    this.mapsAPILoader.load().then(() => {
      let geocoder;
      geocoder = new google.maps.Geocoder;
      let latlng;
      latlng = { lat: this.latitude, lng: this.longitude };
      let that;
      that = this;
      geocoder.geocode({ 'location': latlng }, function (results) {
        if (results[0]) {
          that.zoom = 15;
          that.currentLocation = results[0].formatted_address;
          that.Search = {
            lat: latlng.lat,
            lng: latlng.lng,
            search_address: that.currentLocation
          };
          that.searchaddress = that.currentLocation;
          that.deliveryDetails.Search = that.Search;
        }
      });
    });
  }
  mapLocation(event) {
    // console.log('Clicked', event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getCurrentLocation();
  }
  clickedMarker(label) {
    // console.log(`clicked the marker: ${label}`);
    // this.label = 'xxxxxxx';
  }
  markerDragEnd(event) {
    // console.log('dragEnd', event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getCurrentLocation();
    this.checkDelivery();
  }
  checkDelivery() {
    this.zoneflag = true;
    let zonedetails;
    zonedetails = this.pointInPolygon();
    this.deliveryDetails.bid = this.businessDetails.id;
    if (zonedetails.id !== 0) {
      this.zonematch = true;
      this.deliveryDetails.status = true;
      this.deliveryDetails.type = 'ZONE';
      this.deliveryDetails.details = zonedetails;
      this.deliveryDetails.order = '1';
    } else {
      this.zonematch = false;
      let location;
      location = JSON.parse(this.businessDetails.location);
      let km;
      km = this.distance(location.latitud, location.longitud, this.latitude, this.longitude, 'K');
      let kmtext;
      kmtext = this.distanceText(km);
      let kmdetails;
      kmdetails = this.checkKM(km);
      kmdetails.distance = km;
      kmdetails.distancetext = kmtext;
      if (kmdetails.id !== 0) {
        this.zonematch = true;
        this.deliveryDetails.status = true;
        this.deliveryDetails.type = 'KM';
        this.deliveryDetails.details = kmdetails;
        this.deliveryDetails.order = '1';
      } else {
        this.zonematch = false;
        this.deliveryDetails.status = false;
        this.deliveryDetails.type = '';
        this.deliveryDetails.details = new Object();
        this.deliveryDetails.order = '';
      }
    }
  }
  checkKM(km) {
    let kmdetails;
    kmdetails = {
      'id': 0,
      'delivery': 0,
      'minimum': 0,
      'distance': 0,
      'distancetext': ''
    };

    for (const i of this.allkms) {
      if (Number(km) >= Number(i.dfrom) && Number(km) <= Number(i.dto)) {
        if (kmdetails.id === 0) {
          kmdetails.id = i.id;
          kmdetails.delivery = i.price;
          kmdetails.minimum = i.minimumpur;
        } else {
          if (Number(i.price) < Number(kmdetails.delivery)) {
            kmdetails.id = i.id;
            kmdetails.delivery = i.price;
            kmdetails.minimum = i.minimumpur;
          }
        }
      }
    }
    return kmdetails;
  }
  pointInPolygon() {
    let zonedetails;
    zonedetails = {
      'id': 0,
      'delivery': 0,
      'minimum': 0
    };
    for (const path of this.businessDetails.zone) {
      let zone;
      zone = new Array();
      for (const z of path.zone) {
        let latlng;
        latlng = {
          'lat': z.latitud,
          'lng': z.longitud,
        };
        zone.push(latlng);
      }
      let polygon;
      polygon = new google.maps.Polygon({
        paths: zone,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      let coordinate;
      coordinate = new google.maps.LatLng(this.latitude, this.longitude); // replace with your lat and lng values
      let isWithinPolygon;
      isWithinPolygon = google.maps.geometry.poly.containsLocation(coordinate, polygon);
      if (isWithinPolygon) {
        if (zonedetails.id === 0) {
          zonedetails.id = path.id;
          zonedetails.delivery = path.deliveryfee;
          zonedetails.minimum = path.minimumfee;
        } else {
          if (Number(path.deliveryfee) < Number(zonedetails.delivery)) {
            zonedetails.id = path.id;
            zonedetails.delivery = path.deliveryfee;
            zonedetails.minimum = path.minimumfee;
          }
        }
      }
    }
    return zonedetails;
  }
  deg2rad(deg) { return (deg * Math.PI / 180.0); }
  rad2deg(rad) { return (rad * 180 / Math.PI); }

  distance(lat1, lon1, lat2, lon2, unit) {
    let theta;
    theta = lon1 - lon2;
    let dist = Math.sin(this.deg2rad(lat1))
      * Math.sin(this.deg2rad(lat2))
      + Math.cos(this.deg2rad(lat1))
      * Math.cos(this.deg2rad(lat2))
      * Math.cos(this.deg2rad(theta));
    dist = Math.acos(dist);
    dist = this.rad2deg(dist);
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    } else if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
  distanceText(distance) {
    let distTxt = '';
    if (distance < 1) {
      let m;
      m = distance.toFixed(2);
      distTxt = m.substring(m.indexOf('.') + 1) + '0 m';
    } else {
      distTxt = distance.toFixed(2) + ' km';
    }
    return distTxt;
  }
  checkWhereAreU() {
    if (this.businessDetails.search_type === '1') {
      if (this.zoneflag === false) {
        let location;
        location = JSON.parse(this.businessDetails.location);
        this.Search = {
          lat: location.latitud,
          lng: location.longitud,
          search_address: this.businessDetails.address
        };
        this.deliveryDetails.Search = this.Search;
        this.checkDelivery();
        this.confirmCheck();
      } else {
        this.confirmCheck();
      }
    }

    if (this.businessDetails.search_type === '2') {
      if (this.zipval === '0') {
        this.snackBar.open(this.language.SELECT_ZIPCODE, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      } else {
        let index;
        index = this.checkinArray(this.businessDetails.ziplist, this.zipval, 'id');
        this.Search = {
          search_address: this.businessDetails.ziplist[index].name
        };
        let zipdetails;
        zipdetails = {
          'id': this.businessDetails.ziplist[index].id,
          'name': this.businessDetails.ziplist[index].name,
          'delivery': this.businessDetails.ziplist[index].deliveryprice,
          'minimum': this.businessDetails.ziplist[index].minimumprice
        };
        this.deliveryDetails.bid = this.businessDetails.id;
        this.deliveryDetails.status = true;
        this.deliveryDetails.type = 'ZIPCODE';
        this.deliveryDetails.details = zipdetails;
        this.deliveryDetails.order = '1';
        this.zonematch = true;
        this.confirmCheck();
      }
    }

    if (this.businessDetails.search_type === '3') {
      if (this.zipval === '0') {
        this.snackBar.open(this.language.PLEASE_SELECT_CITY, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      } else {
        let index;
        index = this.checkinArray(this.businessDetails.citylist, this.zipval, 'id');
        this.Search = {
          search_address: this.businessDetails.citylist[index].name
        };
        let citydetails;
        citydetails = {
          'id': this.businessDetails.citylist[index].id,
          'name': this.businessDetails.citylist[index].name,
          'delivery': this.businessDetails.citylist[index].deliveryprice,
          'minimum': this.businessDetails.citylist[index].minimumprice
        };
        this.deliveryDetails.bid = this.businessDetails.id;
        this.deliveryDetails.status = true;
        this.deliveryDetails.type = 'CITY';
        this.deliveryDetails.details = citydetails;
        this.deliveryDetails.order = '1';
        this.zonematch = true;
        this.confirmCheck();
      }
    }

    if (this.businessDetails.search_type === '4') {
      if (this.zipval === '0') {
        this.snackBar.open(this.language.PLEASE_SELECT_AREA, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      } else {
        let index;
        index = this.checkinArray(this.businessDetails.arealist, this.zipval, 'id');
        this.Search = {
          search_address: this.businessDetails.arealist[index].name
        };
        let areadetails;
        areadetails = {
          'id': this.businessDetails.arealist[index].id,
          'name': this.businessDetails.arealist[index].name,
          'delivery': this.businessDetails.arealist[index].deliveryprice,
          'minimum': this.businessDetails.arealist[index].minimumprice
        };
        this.deliveryDetails.bid = this.businessDetails.id;
        this.deliveryDetails.status = true;
        this.deliveryDetails.type = 'AREA';
        this.deliveryDetails.details = areadetails;
        this.deliveryDetails.order = '1';
        this.zonematch = true;
        this.confirmCheck();
      }
    }
  }
  confirmCheck() {
    if (this.zonematch === false) {
      this.snackBar.open(this.language.YOUR_AREA_IS_NOT_IN_DELIVERY, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    } else {
      this.deliveryDetails.Search = this.Search;
      this.dialogRef.close(this.deliveryDetails);
    }
  }
  pickupSearch() {
    let deliveryDetails;
    deliveryDetails = {
      status: true,
      type: 'pickup',
      details: new Object(),
      bid: this.businessDetails.id,
      order: '2',
      Search: ''
    };
    this.dialogRef.close(deliveryDetails);
  }
  dineinSearch() {
    let deliveryDetails;
    deliveryDetails = {
      status: true,
      type: 'dinein',
      details: new Object(),
      bid: this.businessDetails.id,
      order: '3',
      Search: ''
    };
    this.dialogRef.close(deliveryDetails);
  }
  onNoClick() {
    let deliveryDetails;
    deliveryDetails = {
      status: false,
      type: '',
      details: new Object(),
      bid: 0,
      order: '',
      Search: ''
    };
    this.dialogRef.close(deliveryDetails);
  }
  checkinArray(a, b, c) {
    for (let i in a) {
      if (a[i][c] === b) {
        return i;
        break;
      }
    }
    return -1;
  }
}
