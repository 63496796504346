import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Globals } from '../globals';

export interface DialogData {
  order: any;
}

@Component({
  selector: 'app-bookingdetails',
  templateUrl: './bookingdetails.component.html',
  styleUrls: ['./bookingdetails.component.css']
})
export class BookingdetailsComponent {
  public globals: Globals;
  public bookingDetails: any;
  public language;

  constructor(
    public dialogRef: MatDialogRef < BookingdetailsComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.bookingDetails = data.order;
  }
  onNoClick() {
    this.dialogRef.close();
  }

}
