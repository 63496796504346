import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
  public globals: Globals;
  public businessPhoto: any = [];
  public bDetails: any;
  public language;
  public photoResponse: boolean = false;
  constructor(
    public comservice: CommonService,
    public apiservice: ApiService,
    private spinner: NgxSpinnerService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
  }

  ngOnInit() {
    this.bDetails = this.comservice.getService('businessDetails');
    this.apiservice.getPhotoByBusiness(this.bDetails.id).subscribe(response => {
      this.businessPhoto = response;
      this.photoResponse = true;
    });
  }
}
