import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  title: any;
  note: any;
  ok: any;
  cancel: any;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {

  constructor(
    public dialogRef: MatDialogRef < AlertComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}
