import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { CommonService } from './../common.service';
import { DishComponent } from '../dish/dish.component';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';

export interface DialogData {
  suggestProduct: any;
  categoryDetails: any;
  businessDetails: any;
  CartDish: any;
}

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent {
  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  public suggestProduct: any = [];
  public language;
  public categoryDetails: any;
  public businessDetails: any;
  public CartDish: any;
  public dish: any;
  public dishTotal: any;
  public orderType: any;
  public suggestProductDetails: any = [];
  public vegImage = './assets/images/veg.png';
  public nonvegImage = './assets/images/non-veg.png';

  constructor(
    private spinner: NgxSpinnerService,
    private comservice: CommonService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef < SuggestionComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.suggestProductDetails = data.suggestProduct;
    this.businessDetails = data.businessDetails;
    this.CartDish = data.CartDish;
    this.orderType = this.comservice.get('orderType');
  }
  openProduct(catid, dish) {
    if (dish.sets !== undefined) {
      for (const sets of dish.sets) {
        if (sets.options.length > 0) {
          for (const option of sets.options) {
            if (option.choice.length > 0) {
              for (const choice of option.choice) {
                choice.checked = false;
              }
            }
          }
        }
      }
    }

    if (dish.sets.length > 0 || dish.ingredientsarray.length > 0) {
      const dishdialogRef = this.dialog.open(DishComponent, {
        width: '700px',
        data: {
          dish: dish,
          business: this.businessDetails,
          CartDish: (this.comservice.get('cartDetails') === '') ? new Array() : this.comservice.get('cartDetails'),
          type: 0
        },
        disableClose: true
      });

      dishdialogRef.afterClosed().subscribe(result => {
        if (result.status === true) {
          this.CartDish = result.cartDish;
          this.comservice.set('cartDetails', this.CartDish);
          this.dataSharingService.isCartAdded.next(true);
        }
      });
    } else {
      this.dish = dish;
      this.dishTotal = this.dish.price;
      this.addToCart();
    }
  }
  addToCart() {
    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };

    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': 1,
      'data' : new Array()
    };

    let activeIngredients;
    activeIngredients = new Array();

    let data;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'quantity': 1,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      }
    };

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (parent_index === -1) {
      this.CartDish.push(business);
    }
    parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    let index;
    index = this.CheckAddtoCart(data.id, parent_index);
    if (index === -1) {
      dish.data.push(data);
      this.CartDish[parent_index].dish.push(dish);
    } else {
      this.CartDish[parent_index].dish[index].quantity = parseInt(this.CartDish[parent_index].dish[index].quantity, 10) + 1;
      if (data.sets.length === 0) {
        let index_data;
        index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
        if (index_data === -1) {
          this.CartDish[parent_index].dish[index].data.push(data);
        } else {
          this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
          this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
          this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
          this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
        }
      } else {
        let index_data;
        index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
        if (index_data === -1) {
          this.CartDish[parent_index].dish[index].data.push(data);
        } else {
          this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
          this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
          this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
          this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
        }
      }
    }
    if (this.CartDish.length > 0) {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
  }
  CheckBusinessAddtoCart(d) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckAddtoCart(d, index) {
    let b;
    b = this.CartDish[index].dish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckData(cartdata, data) {
    if (data.sets.length === 0) {
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }
  }
  CheckDataSetExist(cartdata, data) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);
        if (arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }
  arrayDiff(arr1, arr2) {
    let diff;
    diff = {
      'arr1': '',
      'arr2': '',
      'concat': ''
    };

    diff.arr1 = arr1.filter(function (value) {
      if (arr2.indexOf(value) === -1) {
        return value;
      }
    });

    diff.arr2 = arr2.filter(function (value) {
      if (arr1.indexOf(value) === -1) {
        return value;
      }
    });

    diff.concat = diff.arr1.concat(diff.arr2);

    return diff;
  }
  onNoClick() {
    let response;
    response = {
      status: false
    };
    this.dialogRef.close(response);
  }
}
