<div class="new-pop">
  <h1>{{language.BOOKING_ID}} # {{bookingDetails.id}} <small
      class="order_status">({{bookingDetails.statustext}})</small></h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="booking-bdy">
    <div class="row ">
      <div class="col-md-6">
        <div class="mdl-dtls bookingdiv">
          <h4>{{language.BUSINESS_DETAILS}}</h4>
          <p>
            <span>
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </span> {{bookingDetails.bname}}
          </p>
          <p>
            <span>
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </span> {{bookingDetails.baddress}}
          </p>
          <p>
            <span>
              <i class="fa fa-phone" aria-hidden="true"></i>
            </span> {{bookingDetails.bmobile}}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mdl-dtls bookingdiv">
          <h4>{{language.USER_DETAILS}}</h4>
          <p>
            <span>
              <i class="fa fa-user" aria-hidden="true"></i>
            </span> {{bookingDetails.name}}
          </p>
          <p>
            <span>
              <i class="fa fa-phone" aria-hidden="true"></i>
            </span> {{bookingDetails.cel}}
          </p>
          <p>
            <span>
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </span> {{bookingDetails.email}}
          </p>
          <p>
            <span>
              <i class="fa fa-clock-o" aria-hidden="true"></i>
            </span> {{bookingDetails.bookingdate}} - {{bookingDetails.bookingtime}}
          </p>
          <p>
            <span>
              <i class="fa fa-users" aria-hidden="true"></i>
            </span> {{bookingDetails.person}}
          </p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="bookingDetails.notes != '' || bookingDetails.statuscomment != ''">
      <div class="col-md-6" *ngIf="bookingDetails.notes != ''">
        <p>
          <span>
            <i class="fa fa-volume-down" aria-hidden="true"></i>
          </span> {{bookingDetails.notes}}
        </p>
      </div>
      <div class="col-md-6" *ngIf="bookingDetails.statuscomment != ''">
        <p>
          <span>
            <i class="fa fa-volume-down" aria-hidden="true"></i>
          </span> {{bookingDetails.statuscomment}}
        </p>
      </div>
    </div>
  </div>
</div>
