<div class="row">
  <div class="col-md-6 col-sm-6 col-xs-12" *ngFor="let offer of businessOffers">
    <div class="">
      <mat-card class="offer-item">
        <a href="javascript:void(0);" style="cursor: default">
          <img [lazyLoad]="foodImage">
          <div class="contant">
            <h4>{{offer.text}}</h4>
            <h5>{{language.MINIMUM_PURCHASE}} <span>{{offer.min_purchase}}</span></h5>
            <p>{{language.VALID_UPTO}} {{offer.valid_upto}}</p>
          </div>
        </a>
      </mat-card>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="offerResponse && businessOffers.length == 0">
    <div class="">
      <mat-card class="no-item">
        <p>{{language.NO_OFFERS_POSTED}}</p>
      </mat-card>
    </div>
  </div>
</div>
