<div class="new-pop">
  <h1>{{language.CONTACT}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls"><svg id="Capa_1"
      enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg></a>

  <div class="form-group">
    <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.NAME_TEXT}}" [(ngModel)]="contactDetails.name" #name>
  </div>
  <div class="form-group">
    <input (keypress)="omit_special_char($event)" autocomplete="off" placeholder="{{language.EMAIL}}" [(ngModel)]="contactDetails.email" #email>
  </div>
  <div class="form-group">
    <textarea (keypress)="omit_special_char($event)" placeholder="{{language.MESSAGE}}" [(ngModel)]="contactDetails.message" #message></textarea>
  </div>
  <button (click)="contactBusiness()" class="cmn-button" cdkFocusInitial>{{language.SUBMIT}}</button>
</div>
